.search-contain {
  position: absolute;
  top: 111px;
  left: 121px;
  z-index: 1000;
  min-width: 350px;
  transition: all .3s ease-in-out;
  @media (max-width: 1281px) {
    top: 81px;
    left: 91px;
  }
  &.side-bar-open {
    transform: translateX(195px);
    @media (max-width: 1281px) {
      left: 0;
      transform: translateX(266px);
    }
  }
  &.noSideBar {
    left: 32px;
  }
  &.add-request-form {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    input {
      height: 40px;
      border-radius: 5px;
      box-shadow: none;
      border: 1px solid #dfe6ee;
      outline: 0;
      width: 100%;
      padding: 0;
      padding-left: 37px;
      background-color: #fff;
      font-size: 15px;
      font-family: 'Roboto';
    }
    .search-icon {
      position: absolute;
      top: 0;
      left: 16px;
      padding: 0;
    }

  }
  input {
    outline: 0;
    border: 0;
    height: 50px;
    width: 100%;
    border-radius: 25px;
    padding: 0 30px;
    padding-left: 46px;
    box-shadow: 0 4px 10px 0 rgba(34, 43, 69, 0.1);
    background-color: #fff;
    font-size: 15px;
    font-family: 'Roboto';
  }
  ::-webkit-input-placeholder { /* Edge */
      color: #9095a2;
      font-size: 15px;
      font-family: 'Roboto';
  }
    
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #9095a2;
      font-size: 15px;
      font-family: 'Roboto';
  }

  ::placeholder {
      color: #9095a2;
      font-size: 15px;
      font-family: 'Roboto';
  }
}
.search-reports{
  min-width: 350px;
  transition: all .3s ease-in-out;
  input {
    height: 40px;
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid #dfe6ee;
    outline: 0;
    width: 100%;
    padding: 0;
    padding-inline-start: 37px;
    background-color: #fff;
    font-size: 15px;
    font-family: 'Roboto';
  }
}
.form{
  min-width: 488px  !important;
  input {
  
    border-radius: 4px !important;
  
  }

}
.search-icon {
  height: 100%;
  display: flex;
  padding: 0px 16px;
  position: absolute;
  align-items: center;
  pointer-events: none;
  justify-content: center;
}

.clear-icon{
  height: 100%;
  display: flex;
  padding: 0px 16px;
  position: absolute;
  bottom: 0%;
  right: 0%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.search-results {
  z-index: 1002;
  padding: 16px;
  box-shadow: 0 2px 10px 0 rgba(34, 43, 69, 0.1);
  border: 0 !important;
}
.testBall{
  padding: 30px;
}


html[dir=rtl] {
  .clear-icon {
    right: auto;
    left: 0;
  }
  .search-contain {
    left: auto;
    right: 121px;
    @media (max-width: 1281px) {
      left: auto;
      right: 91px;
    }

    &.side-bar-open {
      transform: translateX(-195px);
      @media (max-width: 1281px) {
        right: 0;
        transform: translateX(-266px);
      }
    }


    &.noSideBar {
      left: auto;
      right: 32px;
    }
    &.add-request-form {
      left: auto;
      right: 0;
      input {
        padding-left: 0;
        padding-right: 37px;
      }
      .search-icon {
        left: auto;
        right: 16px;
      }
  
    }
    input {
      padding-left: 15px;
      padding-right: 46px;
    }
    
  }
  
  
  
}