body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  position: absolute;
  top: 1rem;
  left: 1rem;
  color: #281414;
  z-index: 10;
  margin: 0;
  padding: 0;
}

/* 
.search input {
  padding: 0.5rem;
  font-size: 1rem;
  width: 80%;
  border: 2px #2f2f2f69 solid;
} */

.locate {
  position: absolute;
  top: 7rem;
  right: 1rem;
  background: none;
  border: none;
  z-index: 10;
}
.locate img {
  width: 30px;
  cursor: pointer;
}
