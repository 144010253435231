.portal-head {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 16px;
}
.portal-table-contain {
    margin-top: 32px;
    .portal-table-head {
        height: 163px;
        background-color: #f7f8fa;
        padding: 0 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 1281px) {
            height: auto;
            padding: 16px;
        }
        &.heightAuto {
            height: auto;
            padding: 32px 48px;
            @media (max-width: 1281px) {
                padding: 16px;
            }
            .portal-search {
                margin-top: 0;
            }
        }
        .portal-table-head-title,
        .portal-table-head-searchAction {
            width: calc(100% - 250px);
        }
        .portal-table-head-action {
            width: 210px;
        }
        .portal-title {
            strong {
                color: #485670;
                font-size: 20px;
                padding-right: 8px;
            }
            span {
                font-size: 14px;
                color: #7a808f;
            }
        }
        .portal-search {
            max-width: 527px;
            min-width: 527px;
            margin-top: 16px;
            @media (max-width: 1281px) {
                min-width: auto;
                max-width: 80%;
            }
        }
    }
    .portal-table-body {
        padding: 32px 48px;
        background-color: #fff;
        @media (max-width: 1281px) {
            padding: 16px;
        }
    }
}
.portal-table {
    overflow-x: auto;
    table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        min-width: 1420px;
        &.policeman-table {
            min-width: 768px;
        }
        td, th {
            padding: 14px 16px;
            color: #313541;
            
        }
        th {
            &.userNameRow {
                min-width: 185px;
            }
            &.emailRow {
                min-width: 250px;
            }
            &.actionsRow {
                min-width: 185px;
            }
        }
        thead {
            tr {
                background-color: #fff;
                th {
                    font-size: 18px;
                    @media (max-width: 1281px) {
                        font-size: 15px;
                    }
                    &.militaryRow {
                        min-width: 200px;
                    }
                    &.phoneRow {
                        min-width: 200px;
                    }
                    &.roleRow {
                        min-width: 150px;
                    }
                    &.statusRow {
                        min-width: 150px;
                    }
                    &.requestId {
                        min-width: 150px;
                    }
                    &.jobId {
                        min-width: 200px;
                    }
                    &.chekcbox-column {
                        min-width: 80px;
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    font-size: 16px;
                    font-weight: 500;
                    word-break: break-all;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    span {
                        display: block;
                        max-width: 250px;
                        word-break: break-all;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    @media (max-width: 1281px) {
                        font-size: 14px;
                    }
                    &:first-child {
                        max-width: 250px;
                        min-width: 250px;
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;

                    }
                    &:last-child {
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }
                    &.requestId {
                        min-width: 150px;
                    }
                    &.jobId {
                        min-width: 200px;
                    }
                    &.chekcbox-column {
                        min-width: 80px;
                    }

                }
                &:nth-child(odd) {
                    background-color: #f4f7fa;
                }
            }
        }

        .table-edit {
            width: 30px;
            height: 30px;
            background-color: #fff;
            box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.07);
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img {
                width: 15px;
                max-height: 15px;
            }
        }    
    }
}

.table-status {
    display: flex;
    align-items: center;
    min-width: 172px;
    &.disable {
        opacity: 0.5;
    }
    &.user-form-status {
        span {
            font-size: 14px;
            &:after {
                top: 6px;
            }
            &:before {
                top: 3px;
            }
        }
    }
    span {
        padding-right: 8px;
        padding-left: 20px;
        position: relative;
        min-width: 113px;
        &:after {
            content: "";
            position: absolute;
            top: 8px;
            left: 2px;
            z-index: 2;
            width: 8px;
            height: 8px;
            border-radius: 100%;
        }
        &:before {
            content: "";
            position: absolute;
            top: 5px;
            left: -1px;
            z-index: 1;
            width: 14px;
            height: 14px;
            border-radius: 100%;
        }
    }
    &.active {
        span {
            &:before {
                background-color: rgba(68, 194, 103, 0.32);
                
            }
            &:after {
                background-color: #44c267;
            }
        }
    }
    &.deactive {
        span {
            &:before {
                background-color: rgba(245, 190, 98, 0.3);
                
            }
            &:after {
                background-color: #f5be62;
            }
        }
    }
}


html[dir="rtl"] {
    .portal-table-contain {
        .portal-table-head {
            .portal-title {
                strong {
                    padding-right: 0;
                    padding-left: 8px;
                }
            }
        }
        .site-btn {
            img {
                transform: none;
            }
        }
    }
    .portal-table {
    
        table {
           
            tbody {
                tr {
                    td {
                        &:first-child {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                            border-top-right-radius: 4px;
                            border-bottom-right-radius: 4px;
    
                        }
                        &:last-child {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;

                            border-top-left-radius: 4px;
                            border-bottom-left-radius: 4px;

                        }
                        &.ltr {
                            direction: ltr;
                        }
    
                    }
                }
            }
    
           
        }
    }
    
    .table-status {
        
        span {
            padding-right: 20px;
            padding-left: 8px;
            min-width: 99px;

            &:after {
                left: auto;
                right: 2px;
            }
            &:before {
                left: auto;
                right: -1px;
            }
        }
    }
}