.report-fixed-foot {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 171px;
    width: calc(100% - 237px);
    z-index: 99;
    @media (max-width: 1281px) {
        width: calc(100% - 107px);
        left: 91px;
    }
    .report-fixed-foot-data {
        background: #fff;
        box-shadow: 0 4px 10px 0 rgba(34, 43, 69, 0.1);
        padding: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        
    }
    .reports-selected-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #44c267;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        width: 60%;
        max-width: 200px;
        padding: 0 16px;
        height: 40px;
        border-radius: 35.5px;
        user-select: none;
        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
            img {
                cursor: not-allowed;

            }
        }
        span {
            margin-right: 8px;

        }
        img {
            cursor: pointer;
        }
    }
    .site-actions-wrap {
        align-self: flex-end;
    }
}

//- site actions
.site-actions-wrap {
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        li {
            &:not(:last-child) {
                margin-right: 16px;
            }
            .site-btn {
                height: 40px;
                min-width: 139px;
                padding: 0;
                position: relative;
                &.red {
                    color: #ff5757;
                    background-color: rgba(255, 87, 87, 0.1);
                }
            }
        }
    }
}

//- report details
.report-details-wrapper {
    padding: 0 16px;
    .request-report-wrap {
        padding-top: 0;
        .request-report-data {
            background-color: transparent;
            box-shadow: unset;
            border-radius: 0;
            .head-level-1 {
                padding: 0;
                flex-wrap: wrap;
                .head-back-wrap,
                .head-actions {
                    margin: 8px 0;
                }
            }
        }
        .request-report-body {
            padding: 0;
            margin-top: 32px;
            .body {
                padding: 0;
                .total-card {
                    border-radius: 15px;
                    padding: 24px;
                }
                .card {
                    border: 0;
                    border-top-right-radius: 15px;
                    border-top-left-radius: 15px;
                    padding: 24px;
                    margin-top: 0;
                }
                .reportDetailsChartTypeHeader {
                    margin-top: 48px;
                    .compare-header-left,
                    .compare-header-right {
                        
                        max-width: 50%;
                    }
                }
            }
            .header-right {
                flex-direction: column;
                align-items: flex-end;
                margin: 8px 0;
                
            }
            .header-left {
                margin: 8px 0;
            }

            .compare-header {
                .compare-header-left,
                .compare-header-right {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    width: auto;
                    @media (max-width: 768px) {
                        width: 100%;
                        max-width: 100% !important;
                    }
                    .header-left {
                        margin-right: 24px;
                    }
                    .selected-locations-wrap {
                        max-width: 376.35px;
                        align-self: flex-start;
                    }
                    .full-width {
                        width: 100%;
                        border-right: 0;
                    }
                }
                .compare-header-right {
                    .small-Oval {
                        &:after {
                            background-color: #24d392;
                            background: linear-gradient(90deg, #24d392 0%, #24d392 0%, #487863 100%);
                        }
                    }
                }
                
            }
            .compare-chart-card {
                border-top-right-radius: 0 !important;
                border-top-left-radius: 0 !important;
            }
            .compare-chart-status-head {
                display: flex;
                justify-content: flex-end;
                margin-top: 16px;
                margin-bottom: 48px;
                .advanced-filter-contain {
                    min-width: 200px;
                }
                .request-select {
                    .request-select-head {
                        width: 100%;
                        border-radius: 25px;
                        background-color: #fff;
                        box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.1);
                        border: 0;
                    }
                    .request-select-body {
                        display: block;
                    }
                }
            }
        }
    }
    .selected-date-wrap {
        display: flex;
        align-self: center;
        width: 100%;
        .white-icon-wrap {
            margin-right: 8px;
            height: 30px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            border-radius: 100%;
        }
        p {
            color: #fff;
            font-size: 14px;
            margin: 0;
            padding: 0;
            padding-top: 5px;
        }
    }
    .selected-locations-wrap {
        margin-top: 8px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        .selected-location-item {
            padding: 4px 16px;
            border-radius: 20px;
            font-size: 12px;
            background-color: #cef5d8;
            margin: 8px 8px 0 0;
        }
    }
    .report-tabs-head {
        display: flex;
        justify-content: flex-end;
    }
}
//- head
.head-back-wrap {
    display: flex;
    align-items: center;
    .head-back {
        width: 38px;
        height: 38px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #44c267;
        margin-right: 16px;
        cursor: pointer;
        img {
            max-width: 100%;
        }
    }
    .head-title {
        width: calc(100% - 54px);
        p {
            span {
                color: #485670 !important;
                
            }
            strong {
                color: #2ebb55;
                margin: 0;
                padding: 0 4px;
            }
        }
    }
}

//- dobule map container
.map-status-compare {
    .double-map-wrapper {
        display: flex;
        flex-wrap: wrap;
        .double-map-item {
            width: calc(50% - 16px);
            &:first-child {
                margin-right: 32px;
            }
            @media (max-width: 992px) {
                width: 100%;
                &:first-child {
                    margin-right: 0;
                    margin-bottom: 32px;
                }
            }
            strong {
                display: block;
                text-align: center;
                color: #485670;
                font-size: 24px;
                margin-bottom: 32px;
            }
            &.status-chart {
                .total-card,
                .card  {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
    
                }
            }
            .double-map-item-map {
                height: 620px;
                display: flex;
                align-items: center;
                justify-content: center;
                @media (max-width: 992px) {
                    height: 350px;
                }
                #map {
                    border-radius: 0 !important;
                }
            }
        }
    }
}

//- map status bar
.map-status-bar-contain {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px;
    border-radius: 33px;
    border: solid 1px #dfdfdf;
    margin-top: 32px;
    @media (max-width: 992px) {
        padding: 16px;
        border-radius: 15px;
    }
    .map-status-bar-wrap {
        width: 100%;
        max-width: 795px;
    }
    .map-status-bar-progress {
        height: 20px;
        width: 100%;
        background: #384d8c;
        background-image: linear-gradient(to right, #7fd3ff 0%, #384d8c 100%);
        border-radius: 10px;
    }
    .map-status-bar-title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
        span {
            font-size: 14px;
            font-weight: 500;
            color: #485670;
            @media (max-width: 992px) {
                font-size: 12px;
            }
        }
    }
    .map-status-bar-text {
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
            font-size: 14px;
            font-weight: 500;
            color: #485670;
            @media (max-width: 992px) {
                font-size: 12px;
            }
        }
    }
}

.compare-report-modal-title {
    color: #222b45;
    font-size: 14px;
    display: block;
    margin-bottom: 32px;
}
tr.disable {
    opacity: 0.4;
}

.remove-shadow {
    &.removed {
        box-shadow: unset !important;
    }
}
html[dir=rtl] {
    .report-fixed-foot {
        right: 171px;
        left: auto;
        @media (max-width: 1281px) {
            left: auto;
            right: 91px;
        }
        .reports-selected-wrap {
            span {
                margin-right: 0;
                margin-left: 8px;
            }
        }
    }
    
    //- site actions
    .site-actions-wrap {
        ul {
            li {
                &:not(:last-child) {
                    margin-right: 0;
                    margin-left: 16px;
                }
                
            }
        }
    }

    //- report details
    .report-details-wrapper {
        .request-report-wrap {
            .request-report-body {
                .compare-header {
                    .compare-header-left,
                    .compare-header-right {
                        .header-left {
                            margin-right: 0;
                            margin-left: 24px;
                        }
                        
                        .full-width {
                            border-left: 0;
                        }
                    }
                    
                }
                
                
            }
        }
        .selected-date-wrap {
            .white-icon-wrap {
                margin-right: 0;
                margin-left: 8px;
            }   
        }
    }

    //- head
    .head-back-wrap {
        .head-back {
            margin-right: 0;
            margin-left: 16px;
            img {
                transform: rotate(180deg);
            }
        }
    }

    //- dobule map container
    .map-status-compare {
        .double-map-wrapper {
            .double-map-item {
                &:first-child {
                    margin-right: 0;
                    margin-left: 32px;
                }
                @media (max-width: 992px) {
                    &:first-child {
                        margin-right: 0;
                        margin-left: 0;
                    }
                }
            
                
            }
        }
    }

    //- map status bar
    .map-status-bar-contain {
        .map-status-bar-progress {
            background-image: linear-gradient(to left, #7fd3ff 0%, #384d8c 100%);
        }
    }

    .selected-date-wrap {
        p {
            direction: ltr;
        }
    }
    .EN_IMP {
        direction: ltr !important;
    }
    .AR_IMP {
        direction: rtl !important;
    }
    
}

