.request-form-contain {
    border: solid 1px rgba(22, 32, 53, 0.1);
    border-radius: 5px;
    background-color: #fff;

    .request-form-head,
    .request-form-body {
        padding: 16px;
    }

    .request-form-head {
        border-bottom: solid 1px rgba(22, 32, 53, 0.1);
    }

    .request-form-title {
        display: flex;
        align-items: center;

        img {
            margin-inline-end: 8px;
        }

        strong {
            font-size: 16px;
            color: #485670;
            text-transform: capitalize;
        }
    }
}

.request-form-action {
    .form-aciton {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
            cursor: pointer;
            text-decoration: underline;
            color: #44c267;
            font-size: 16px;
            margin: 0;
            margin-top: 16px;

            &.disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
    }

    .site-btn {
        min-width: 180px;
        font-size: 16px;

        &.full {
            width: 100%;
        }

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }

        .spinner {
            margin-left: 16px;
        }
    }
}

.request-form {
    .form-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-left: 0;
        margin-right: 0;

        &:not(:last-child) {
            margin-bottom: 16px;
        }

        .form-col {
            width: 100%;

            &> {
                strong {
                    display: block;
                    margin-bottom: 8px;
                    color: #222b45;
                    font-size: 14px;
                    text-align: start;

                    span {
                        color: rgba($color: #485670, $alpha: 0.7);
                        font-size: 12px;
                        padding-left: 4px;
                    }
                }
            }

            &.half {
                width: calc(50% - 4px);

                &:first-of-type {
                    margin-right: 8px;
                }
            }

            .site-input {
                height: 40px;
                border-radius: 5px;
                box-shadow: none;
                border: 1px solid #dfe6ee;

                &.disabled {
                    background-color: #f3f3f4;
                    border: 0;
                    cursor: not-allowed;

                }

            }

            input {
                &.invalid-input {
                    border-color: red;
                }
            }

            &.invalid-col {
                .error-msg {
                    display: block !important;
                }
            }

        }

        .phone-number-wrap {
            display: flex;

            .country-code {
                width: 85px;
                margin-inline-end: 8px;
            }

            .phone-number {
                width: calc(100% - 92px);
            }
        }

        .injured-wrap {
            display: flex;
            user-select: none;

            .injured-action-item {
                width: 40px;
                height: 40px;
                background-color: #44c267;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &.disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }

                &.minus {
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                }

                &.add {
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }

            .site-input {
                border-radius: 0;
                width: calc(100% - 80px);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .location-note {
            color: rgba(49, 53, 65, 0.7);
            font-size: 11px;
            margin-top: 6px;
            margin-bottom: 0;
        }

        .error-msg {
            color: red;
            margin-top: 6px;
            margin-bottom: 0;
            font-size: 11px;
            display: none;
        }
        .success-msg {
            color: #44c267;
            font-size: 16px;
            margin-top: 6px;
            margin-bottom: 0;
        }
        .send-msg {
            color: rgba(22, 32, 53, 0.9);
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;
            text-decoration: underline;
        }
        &.forget-password-row {
            margin: 32px 0;
            justify-content: flex-end;
            .form-col {
                width: auto;
                strong {
                    color: #44c267;
                    cursor: pointer;
                    text-decoration: underline;
                    margin-bottom: 0;
                }
            }
        }
        &.otp-form-row {
            justify-content: center;
            .form-col {
                width: 50px;
                margin: 0 8px;
                .site-input {
                    height: 50px;
                    text-align: center;
                }
            }
        }

    }


}

.request-select {
    position: relative;
    user-select: none;

    .request-select-head {
        height: 40px;
        padding: 8px 16px;
        border-radius: 5px;
        border: 1px solid #dfe6ee;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        &.disabled {
            background-color: #f3f3f4;
            border: 0;
            cursor: not-allowed;
        }

        &.selected {
            background-color: #44c267;
            border-color: #44c267;

            .request-select-text {
                span {
                    color: #fff;
                }

                &.offset {
                    svg {
                        g {
                            stroke: #fff;
                        }
                    }
                }

                &.impound {
                    svg {
                        g {
                            fill: #fff;
                        }
                    }
                }
            }

            .request-select-img {
                opacity: 1;
            }

        }

        .request-select-img {
            opacity: 0;
        }

        .request-select-text {
            display: flex;
            align-items: center;

            img,
            svg {
                margin-inline-end: 8px;
            }

            &.offset {
                svg {
                    g {
                        stroke: #44C267;
                    }
                }
            }

            &.impound {
                svg {
                    g {
                        fill: #44C267;
                    }
                }
            }

            span {
                font-size: 14px;
                color: #313541;
                text-transform: capitalize;
                display: block;
                max-width: 140px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }

    .request-select-body {
        margin-top: 2px;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 3;
        display: none;

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            background-color: #fff;
            border-radius: 5px;
            box-shadow: 0 2px 6px 0 #b0bac5;

            li {
                display: flex;
                align-items: center;
                padding: 8px 16px;
                cursor: pointer;

                &:hover {
                    background-color: #f4f7fa;
                }

                img {
                    margin-right: 8px;
                }

                span {
                    font-size: 14px;
                    color: #313541;
                    text-transform: capitalize;
                }
            }
        }
    }
}

.request-location {
    .request-location-item {
        display: flex;

        img {
            margin-inline-end: 8px;
            align-self: flex-start;
        }

        &:first-child {
            margin-top: 16px;
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #dfe6ee;
        }
        &.noMargin {
            border: 0;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        .location-txt {
            padding: 0;
            margin: 0;
            font-size: 14px;
            color: #313541;
        }

        .location-note {
            margin-top: 0;
        }
    }
}

html[dir=rtl] {
    .request-form {
        .form-row {
            .form-col {
                &> {
                    strong {
    
                        span {
                            padding-right: 4px;
                        }
                    }
                }
            }
            .injured-wrap {
                .injured-action-item {
                    &.minus {
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                        border-top-left-radius: 0px;
                        border-bottom-left-radius: 0px;
                    }

                    &.add {
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                    }
                }
            }
            .phone-number-wrap {
                direction: ltr;
                ::-webkit-input-placeholder {
                    text-align: right;
                }
                
                :-ms-input-placeholder { 
                    text-align: right;
                }
                
                ::placeholder {
                    text-align: right;
                }
            }
            &.otp-form-row {
                direction: ltr;
            }
        }
    }



}