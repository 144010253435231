@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@400;700;800&display=swap');


html,
body {
    width: 100%;
    height: 100%;
}
#root {
    height: inherit;
}
#map {
    height: 100% !important;
    width: 100% !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
}
// body style
body {
    font-family: 'Roboto', "Helvetica", sans-serif !important;
    box-sizing: border-box;
    &.hideScroll {
        overflow: hidden;
    }
}
* {
    box-sizing: border-box;
}


// general
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

select::-ms-expand {
    display: none;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea,
.react-dropdown-select-input {
    @media (max-width: 768px) {
        font-size: 16px !important;
    }
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    box-shadow: 0 0 0 30px white inset !important;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #bfbfbf;   
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #a0a0a0; 
}


// content wrapper
.data-container {
    width: 100%;
    min-height: calc(100vh - 127px);
    @media (max-width: 1281px) {
        min-height: calc(100vh - 65px);
    }
    padding: 111px 16px 16px 121px;
    @media (max-width: 1281px) {
        padding: 81px 16px 16px 91px
    }
    &.profile {
        padding: 111px 66px 16px 171px;
        background-color: #f0f2f5;
        @media (max-width: 1281px) {
            padding: 81px 16px 16px 91px
        }
    }

    .data-wrapper {
        width: 100%;
        min-height: inherit;
        position: relative;
    }
}



//- spinner
.spinner {
    border: 2px solid #fff;
    border-radius: 50%;
    border-top: 2px solid #44c267;
    width: 15px;
    height: 15px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}
  
/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


//- note
.note {
    padding: 11px 32px;
    display: flex;
    justify-content: space-between;
    &.success {
        background-color: rgba(226, 241, 230, 0.95);
    }
    &.error {
        background-color: #ffe0e0;
    }
    .note-text {
        margin-right: 16px;
        display: flex;
        align-items: center;
        img {
            align-self: flex-start;
        }
        span {
            font-size: 14px;
            padding-left: 8px;
            line-height: 1.3;
        }
    }
    .closeNote {
        cursor: pointer;
        align-self: flex-start;
        position: relative;
        top: 4px;
    }
}

//- site tabs
.site-tabs {
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        li {
            font-size: 16px;
            color: #445772;
            font-weight: 500;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f0f2f5;
            border: 1px solid #d6d6d6;
            padding: 0 32px;
            border-radius: 65px;
            cursor: pointer;
            transition: all .3s ease-in-out;
            &.active {
                background-color: #fff;
                box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.1);
                color: #44c267;
                border: 0;
                position: relative;
                z-index: 1;
                
            }
            &.active {
                &.user {
                    left: 36px;
                    
                }
                &.policeman {
                    //right: 36px;
                    &.not-active {
                        padding-right: 60px;
                    }
                }
            }
            &.not-active {
                &.user {
                    padding-right: 60px;
                    position: relative;
                    left: 36px;
                }
                &.policeman {
                    padding-left: 60px;
                }
            }
            
        }
    }
}

.back-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-back-btn {
    font-weight: 500;
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
}

html[dir=rtl] {
    // body style
    body {
        font-family: 'Almarai' !important;
        text-align: right;
    }

    ::-webkit-input-placeholder {
        font-family: 'Almarai' !important;
        line-height: 1.6;
    }
    
    :-ms-input-placeholder { 
        font-family: 'Almarai' !important;
        line-height: 1.6;
    }
    
    ::placeholder {
        font-family: 'Almarai' !important;
        line-height: 1.6;
    }
    .data-container {
        padding: 111px 121px 16px 16px;
        @media (max-width: 1281px) {
            padding: 81px 91px 16px 16px;
        }

        &.profile {
            padding: 111px 171px 16px 66px;
            @media (max-width: 1281px) {
                padding: 81px 91px 16px 16px;
            }
        }
    }
    
    .note {
        .note-text {
            margin-right: 0;
            margin-left: 16px;
            span {
                padding-left: 0;
                padding-right: 8px;
            }
        }
    }

    .site-tabs {
        ul {
            li {
                &.active {
                    &.user {
                        left: auto;
                        right: 36px;
                    }
                    &.policeman {
                        // right: auto;
                        // left: 36px;
                        
                    }
                }
                &.not-active {
                    &.user {
                        padding-right: 32px;
                        padding-left: 60px;
                        left: auto;
                        right: 36px;

                    }
                    &.policeman {
                        padding-left: 32px;
                        padding-right: 60px;
                    }
                }
                
            }
        }
    }
    .number-text {
        direction: ltr;
    }
}