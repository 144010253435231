.portal-table-head-searchAction{
    .portal-title {
        h3 {
            display: inline-block;
            color: #485670;
            font-size: 20px;
            font-weight: 500;
            padding-right: 10px;
        }
        span {
            font-size: 14px;
            font-weight: 500;
            color: #7a808f;
        }
    }
}
.portal-table{
    table {
        tbody{
            .table-actions{
                display: flex;
                align-items: center;
            }
            .action{
                width: 30px;
                height: 30px;
                background-color: #fff;
                box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.07);
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                cursor: pointer;
                img {
                    width: 15px;
                    max-height: 15px;
                }
            }
        }
    }
}

html[dir="rtl"] {
    .portal-table-head-searchAction{
        .portal-title {
            h3 {
                padding-right: 0;
                padding-left: 10px;
            }
        }
    }
    .portal-table{
        table {
            tbody{
                .action{
                    margin-right: 0;
                    margin-left: 10px;
                }
            }
        }
    }
}