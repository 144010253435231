.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2000;
    width: 100%;
    height: 100%;
    background-color: rgba(72, 86, 112, 0.75);
    backdrop-filter: blur(10px);
    .modal-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: all .3s ease-in-out;
        transform: translateY(10px);
        opacity: 0;
        padding: 48px 16px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        &.active {
            transform: translateY(0);
            opacity: 1;
        }
    }
    .modal-info-wrapper-icon {
        width: 37px;
        height: 37px;
        opacity: 0.6;
    }
    .modal-data {
        background-color: #fff;
        padding: 32px;
        border-radius: 5px;
        position: relative;
        width: 95%;
        max-width: 525px;
        max-height: 100%;
        &.protal-modal {
            padding: 0;
            .profile-card {
                min-width: auto;
                height: 100%;
                border-radius: 5px;
                .profile-card-head {
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    height: 85px;
                }
                .profile-card-body {
                    height: calc(100% - 85px);
                    &.has-error {
                        height: calc(100% - 132px);
                    }
                    overflow-y: auto;
                    -webkit-overflow-scrolling: touch;
                }
                .note {
                    position: absolute;
                    width: 100%;
                    top: 85px;
                    z-index: 2;
                }
            }
        }
        .close-modal {
            position: absolute;
            background-color: #485670;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -20px;
            right: -20px;
            cursor: pointer;
        }
        
        .modal-data-head {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            strong {
                font-size: 18px;
                color: #222b45;
                text-align: center;
                margin-top: 32px;
            }
            p {
                font-size: 14px;
                color: #485670;
            }
        }
        .modal-data-foot {
            margin-top: 32px;
        }

        .request-id {
            box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.07);
            min-height: 40px;
            border-radius: 20px;
            padding: 10px 22px;
            font-size: 14px;
            font-weight: 500;
            color: #222b45;
        }
        .site-btn {
            max-width: 280px;
            margin: 0 auto;
        }
    }
}