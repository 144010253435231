.profile-information-contain {
    min-height: inherit;
    width: 100%;
    .profile-information-wrap {
        min-height: inherit;

        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .profile-information-data {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
    
        }
    }
}


.profile-card {
    background-color: #fff;
    box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.09);
    width: 100%;
    max-width: 593px;
    .profile-card-head {
        padding: 16px 48px;
        background-color: #f0f2f5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .profile-edit {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.07);
            background-color: #fff;
            border-radius: 100%;
            cursor: pointer;
        }
        .user-title-wrap {
            display: flex;
            align-items: center;
            .user-icon {
                width: 50px;
                height: 50px;
                border-radius: 100%;
                background-color: #44c267;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0 2px 10px 0 rgba(133, 133, 133, 0.22);
                margin-right: 16px;
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    top: -2px;
                    right: -2px;
                    width: 54px;
                    height: 54px;
                    border-radius: 50%;
                    z-index: 1;
                    border: 2px solid #fff;
                }
            }
            strong {
                color: #485670;
                font-size: 21px;
                text-transform: capitalize;
            }
        }
        
    }
    .profile-card-body {
        padding: 48px 48px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        .profile-card-information {
            ul {
                margin: 0;
                padding: 0;
                list-style-type: none;
                li {
                    display: flex;
                    flex-wrap: wrap;
                    background-color: rgba($color: #f0f2f5, $alpha: 0.5);
                    padding: 16px;
                    &:not(:last-child) {
                        margin-bottom: 22px;
                    }
                    img {
                        align-self: flex-start;
                        margin-right: 12px;
                    }
                    .profile-card-information-meta {
                        span {
                            display: block;
                            font-size: 14px;
                            color: #7a808f;
                            text-transform: capitalize;
                            font-weight: 500;
                        }
                        p {
                            color: #4f566b;
                            font-size: 16px;
                            font-weight: 500;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}


html[dir="rtl"] {
    
    
    
    .profile-card {
        .profile-card-head {
            
            .user-title-wrap {
                .user-icon {
                    margin-left: 16px;
                    margin-right: 0;
                }
                
            }
            
        }
        .profile-card-body {
            .profile-card-information {
                ul {
                    li {
                        img {
                            margin-left: 12px;
                            margin-right: 0;
                        }
                       
                    }
                }
            }
        }
    }
}