.request-card-item {
    margin-bottom: 22px;
    box-shadow: 0 2px 10px 0 rgba(34, 43, 69, 0.1);
    border-radius: 5px;
    cursor: pointer;
    @media (max-width: 1281px) {
        &:last-child {
            margin-bottom: 180px;
        }
    }

    .request-card-head {
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #f5f5f6;
        @media (max-width: 1281px) {
            flex-wrap: wrap;
        }

        .request-card-head-left {
            @media (max-width: 1281px) {
                margin-bottom: 8px;
                justify-content: space-between;
            }
        }
        .request-card-head-right {
            @media (max-width: 1281px) {
                justify-content: flex-end;
            }
        }
        .request-card-head-left,
        .request-card-head-right {
            display: flex;
            align-items: center;
            @media (max-width: 1281px) {
                width: 100%;
            }
        }

        .request-card-id {
            padding: 2px 12px;
            background-color: #fff;
            border-radius: 12.5px;
            margin-inline-end: 16px;
            font-size: 14px;
            font-weight: bold;
            display: flex;
            align-items: center;

            span {
                padding-right: 4px;
            }

            strong {
                max-width: 120px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        .request-card-status {
            position: relative;
            font-size: 14px;

            strong {
                padding-left: 20px;
                font-weight: 500;
                text-transform: capitalize;
            }

            &:after {
                content: "";
                position: absolute;
                top: 6px;
                left: 2px;
                z-index: 2;
                width: 8px;
                height: 8px;
                border-radius: 100%;
            }

            &:before {
                content: "";
                position: absolute;
                top: 4px;
                left: 0;
                z-index: 1;
                width: 12px;
                height: 12px;
                border-radius: 100%;
            }
        }

        .request-card-time {
            display: flex;
            align-items: center;

            .icon {
                margin-inline-end: 8px;
            }

            strong {
                font-size: 13px;
            }
        }
    }

    &.completed {
        .request-card-head {
            .request-card-status {
                strong {
                    color: #71bbd9;
                }

                &:after {
                    background-color: #71bbd9;
                }

                &:before {
                    background-color: rgba($color: #71bbd9, $alpha: 0.3);
                }
            }
        }
    }

    &.active,
    &.arrived,
    &.onProgess {
        .request-card-head {
            background-color: #f5f5f6;

            .request-card-status {
                strong {
                    color: #44c267;
                }

                &:after {
                    background-color: #44c267;
                }
            }
        }

        .request-card-body {
            background-color: rgba($color: #d8d8d8, $alpha: 0.15);
        }
    }

    &.selected {
        .request-card-head {
            background-color: #485670;

            .request-card-status {
                strong {
                    color: #2ebb55;
                }

                &:after {
                    background-color: #2ebb55;
                }

                &:before {
                    background-color: rgba($color: #2ebb55, $alpha: 0.3);
                }
            }

            .request-card-time {
                color: #fff;

                svg {
                    g {
                        fill: #fff;
                    }
                }
            }
        }

        .request-card-body {
            background-color: rgba($color: #d8d8d8, $alpha: 0.15);
        }
    }

    &.cancelled {
        .request-card-head {
            .request-card-status {
                strong {
                    color: #fb8888;
                }

                &:after {
                    background-color: #fb8888;
                }

                &:before {
                    background-color: rgba($color: #fb8888, $alpha: 0.3);
                }
            }
        }
    }

    &.initiated,
    &.pending {
        .request-card-head {
            .request-card-status {
                strong {
                    color: #c89f5a;
                }

                &:after {
                    background-color: #c89f5a;
                }

                &:before {
                    background-color: rgba($color: #c89f5a, $alpha: 0.3);
                }
            }
        }
    }

    .request-card-head {
        padding: 16px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .request-card-body {
        padding: 16px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;

        .request-card-location {
            position: relative;
            padding-left: 24px;
            font-size: 14px;
            text-transform: capitalize;
            width: calc(100% - 60px);
            max-width: 270px;

            &:after {
                content: "";
                position: absolute;
                top: 4px;
                left: 0;
                width: 12px;
                height: 12px;
                border-radius: 100%;
                background-color: #44c267;
            }

            strong {
                text-align: start;
                color: #7a808f;
                margin-bottom: 8px;
                display: block;
            }

            p {
                text-align: start;
                margin-bottom: 0;
            }
        }

        .request-card-action {
            background-color: rgba($color: #222b45, $alpha: 0.05);
            width: 40px;
            height: 40px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }
}

html[dir=rtl] {
    .request-card-status {
        strong {
            padding-right: 20px;
        }
        &:after {
            right: 2px;
            left: auto;
        }
        &::before {
            right: 0px;
            left: auto;
        }

    }
    .request-card-item {
        .request-card-head {
            .request-card-id {
                span {
                    padding-right: 0;
                    padding-left: 4px;
                }
            }
        }
    }

    .request-card-location {

        &:after {
            left: auto;
            right: 0px;
        }

    padding-right: 24px;
    }
}