

.custom-controls-wrap {
  position: absolute;
  bottom: 64px;
  right: 10px;
  z-index: 10001;
  transition: transform .3s ease-in-out;
  @media (max-width: 1281px) {
    bottom: 16px;
  }
  &.move {
    transform: translateX(-602px);
    @media (max-width: 1281px) {
      transform: translateX(-416px);
    }
  }
  ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 5px;
      background-color: #FFF;
      user-select: none;
      cursor: pointer;

      box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      img {
        &.exit {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

.wrapper {
  .icon_contain {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    position: relative;
    border-radius: 50%;

    & img {
      position: absolute;
      top: 35%;
      left: 25%;
    }

    .icon_wrapper {
      position: relative;
      left: 15%;
      top: 15%;
      width: 35px;
      height: 35px;
      border-radius: 50%;

      span{
        font-size: 14px;
        font-weight: bold;
        position: absolute;
        top: 30%;
        left: 40%;
        color: #FFF;
    
      }
    }

    &.arrived {
      background: rgba(68, 194, 103, 0.32);
      .icon_wrapper {
        background: #44c267;
      }
    }
    &.initiated {
      background: rgba(245, 190, 98, 0.3);
      & img {
        width: 15px;
      }

      .icon_wrapper {
        background: #f5be62;
      }
    }
  }
  .icon_id {
    background-color: #485670;
    padding: 0px 7px;
    box-sizing: border-box;
    border-radius: 12.5px;
    margin-top: 5px;
    & p {
      color: #fff;
    }
  }
}

html[dir="rtl"] {
  .custom-controls-wrap {
    right: auto;
    left: 10px;
    &.move {
      transform: translateX(602px);
      @media (max-width: 1281px) {
        transform: translateX(416px);
      }
    }
  }
  .wrapper {
    .icon_contain {
      & img {
        position: absolute;
      }

      .icon_wrapper {
        left: -15%;
      }
    }
  }
}
