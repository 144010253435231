.card-loader {
    background-color: #fff;
    position: relative;
    overflow: hidden;
  
    &.image {
        height: 180px;
        margin-bottom: 16px;
        display: block;
        background-color: #ededed;
    }
  
    &.data {
        height: 20px;
        margin-bottom: 8px;
        display: block;
        background-color: #ededed;
        &.tableRow {
            margin-bottom: 0;
        } 
    }
    &.input {
        height: 40px;
        width: 100%;
        background-color: #ededed;
    }
  
    &:after {
        content: '';
        background-color: #ededed;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        animation-duration: 0.6s;
        animation-iteration-count: infinite;
        animation-name: loader-animate;
        animation-timing-function: linear;
        background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0) 81%);
        background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0) 81%);
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0) 81%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#00ffffff', GradientType=1);
    }
}
  
  
  // Loader animation
@keyframes loader-animate {
    0% {
        transform: translate3d(-100%, 0, 0);
    }
  
    100% {
        transform: translate3d(100%, 0, 0);
    }
}