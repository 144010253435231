.serverDown-wrap {
    min-height: 100vh;
    width: 100%;
    padding: 64px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    .serverDown-item {
        max-width: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .serverDown-img {
            img {
                display: block;
                max-width: 100%;
            }
        }
        .serverDown-data {
            text-align: center;
            margin-top: 32px;
            strong {
                color: #485670;
                font-size: 32px;
                @media (max-width: 768px) {
                    font-size: 24px;
                }
            }
            p {
                color: #7b8698;
                font-size: 24px;
                margin-top: 16px;
                line-height: 1.3;
                @media (max-width: 768px) {
                    font-size: 16px;
                }
            }
            .serverDown-link {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .site-btn {
                width: 140px;
                height: 40px;
            }
        }
    }
}



//- coming soon
.coming-soon {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% + 32px);
    margin-left: -16px;
}

html[dir="rtl"] {
    .coming-soon {
        margin-left: 0;
        margin-right: -16px;
    }   
}