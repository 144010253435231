.notification-items-wrapper{
    .notification-item {
        background-color: rgba($color: #222b45, $alpha: 0.05);
        padding: 32px;
        cursor: pointer;
        &.seen {
            background-color: #fff;
            border-bottom: 1px solid rgba($color: #979797, $alpha: 0.2);
            .notification-item-data {
                .notification-item-status {
                    font-weight: 400;
                }
            }
            .notification-item-date {
                span {
                    &:after {
                        display: none;
                    }
                }
            }
        }
        &.arrived {
            .notification-item-data {
                    
                &:after {
                    background-color: #44c267;
                }
                &:before {
                    background-color: rgba($color: #44c267, $alpha: 0.3);
                }
            }
        }
        &.completed {
            .notification-item-data {
                    
                &:after {
                    background-color: #71bbd9;
                }
                &:before {
                    background-color: rgba($color: #71bbd9, $alpha: 0.3);
                }
            }
        }

        &.cancelled {

            .notification-item-data {
                    
                &:after {
                    background-color: #fb8888;
                }
                &:before {
                    background-color: rgba($color: #fb8888, $alpha: 0.3);
                }
            }
        }

        &.initiated {
            .notification-item-data {
                    
                &:after {
                    background-color: #c89f5a;
                }
                &:before {
                    background-color: rgba($color: #c89f5a, $alpha: 0.3);
                }
            }
        }

        &.updated {
            .notification-item-data {
                    
                &:after {
                    background-color: #485670;
                }
                &:before {
                    background-color: rgba($color: #485670, $alpha: 0.3);
                }
            }
        }
        
        .notification-item-data {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            position: relative;
            padding-left: 20px;
            &:after {
                content: "";
                position: absolute;
                top: 8px;
                left: 2px;
                z-index: 2;
                width: 8px;
                height: 8px;
                border-radius: 100%;
            }
            &:before {
                content: "";
                position: absolute;
                top: 6px;
                left: 0;
                z-index: 1;
                width: 12px;
                height: 12px;
                border-radius: 100%;
            }
            .notification-item-id {
                font-size: 14px;
                color: #fff;
                background-color: #485670;
                padding: 2px 12px;
                border-radius: 12.5px;
                display: flex;
                align-items: center;
                font-weight: bold;
                margin-inline-end: 8px;
                
                strong {
                    max-width: 120px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
                span {
                    padding-right: 4px;
                }
            }
            .notification-item-status {
                font-size: 16px;
                font-weight: bold;
                color: #485670;
            }
        }
        .notification-item-date {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            span {
                font-size: 12px;
                position: relative;
                color: #485670;
                padding-left: 16px;
                &:after {
                    content: "";
                    position: absolute;
                    top: 5px;
                    left: 0;
                    width: 7px;
                    height: 7px;
                    background-color: #44c267;
                    border-radius: 100%;
                }
            }
        }
    }
}

html[dir=rtl] {

    .notification-items-wrapper{ .notification-item{.notification-item-data{
        padding-left: 0;
        padding-right: 20px;
   
        &:after {
          right: 2px;
          left: auto;
        }
        &:before {
          right: 0px;
          left: auto;
        }
        .notification-item-id {
            span {
                padding-right: 0;
                padding-left: 4px;
            }
        }

    }}}

    .notification-items-wrapper{ .notification-item{.notification-item-date{

    span {
        padding-right: 16px;
        padding-left: 0;
        &:after {
            right: 0;
            left:auto;
        }
    }
}
    }
}
    }

