.header-contain {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    width: 100%;
    height: 95px;
    background-color: #fff;
    @media (max-width: 1281px) {
        height: 65px;
        .site-btn {
            height: 41px;
        }
    }
    &.homepage {
        background-color: rgba($color: #fff, $alpha: 0.9);
    }
    .header-wrap,
    .header-nav,
    .header-actions,
    .sidebar-btn {
        height: 100%;
    }
    .header-wrap {
        display: flex;
        justify-content: space-between;
    }
    .header-nav {
        display: flex;
        .header-nav-list {
            ul {
                list-style-type: none;
                display: flex;
                align-items: center;
                margin: 0;
                padding: 0;
                height: 100%;
                margin-left: 16px;
                li {
                    position: relative;
                    &:not(:last-child) {
                        padding-right: 16px;
                        margin-right: 16px;
                        &:after {
                            content: "";
                            position: absolute;
                            right: 0;
                            top: 50%;
                            margin-top: -15px;
                            width: 1px;
                            height: 30px;
                            background-color: #222b45;
                            opacity: 0.1;
                        }
                    }
                    &.brand-logo {
                        svg {
                            width: 50px;
                            height: 50px;
                        }
                        @media (min-width: 1420px) {
                            svg {
                                width: 80px;
                                height: 80px;
                            }
                        } 
                        @media (max-width: 1281px) {
                            &:nth-child(2) {
                                &:after {
                                    display: none;
                                }
                            }
                            
                        }
                    }
                    &.header-date {
                        display: flex;
                        align-items: center;
                        .header-date-month {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            padding: 8px 14px;
                            background-color: #fff;
                            border-radius: 6px;
                            box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.07);
                            margin-right: 8px;
                            span {
                                font-size: 10px;
                                text-transform: uppercase;
                                line-height: 1;
                                color: rgba(22, 32, 53, 0.66);
                            }
                            strong {
                                font-size: 26px;
                                line-height: 1;
                            }
                        }
                        .header-date-data {
                            display: flex;
                            flex-direction: column;
                            strong {
                                font-size: 14px;
                                line-height: 1;
                            }
                            span {
                                font-size: 14px;
                                color: #7b8698;
                            }
                        }
                    }
                    &.header-info {
                        @media (max-width: 992px) {
                            display: none;
                        }
                        strong {
                            font-size: 21px;
                            color: #485670;
                            line-height: 1;
                            display: block;
                            margin-bottom: 4px;
                            @media (max-width: 1281px) {
                                font-size: 16px;
                                margin-bottom: 0;
                            }
                        }
                        p {
                            font-size: 15px;
                            color: #7a808f;
                            margin: 0;
                            @media (max-width: 1281px) {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
        .sidebar-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 105px;
            padding: 16px;
            background-color: #fff;
            border-right: 1px solid rgba($color: #222b45, $alpha: 0.05);
            transition: all .3s ease-in-out;
            @media (max-width: 1281px) {
                width: 75px;
            }
            span {
                display: none;
                color: #2ebb55;
                font-size: 18px;
                font-weight: 500;
                margin-right: 16px;
                width: 10px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            img {
                transition: all .3s ease-in-out;
            }
            &.active {
                width: 300px;
                @media (max-width: 1281px) {
                    width: 250px;
                }
                span {
                    width: auto;
                    display: block;
                }
                img {
                    transform: rotate(180deg);
                }
            }
            
        }
    }
    .header-actions {
        &.alone {
            padding-left: 32px;
        }
        ul {
            height: 100%;
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0;
            list-style-type: none;
            li {
                &:not(:last-child) {
                    margin-right: 32px;
                }
            }
            
        }
        .request-btn {
            min-width: 224px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            background-color: #485670;
        }
    }
    .header-nested-actions {
        height: 100%;
        display: flex;
        align-items: center;
        padding-right: 66px;
        @media (max-width: 1281px) {
            padding-right: 16px;
        }
    }
    
}



html[dir=rtl] {
    .header-contain {
        .header-nav {
            .header-nav-list {
                ul {
                    margin-right: 16px;
                    margin-left: 0;
                    li {
                        &:not(:last-child) {
                            padding-right: 0;
                            margin-right: 0;

                            padding-left: 16px;
                            margin-left: 16px;
                            &:after {
                                right: auto;
                                left: 0;
                            }
                        }
                        &.header-date {
                            .header-date-month {
                                margin-right: 0;
                                margin-left: 8px;
                            }
                            
                        }
                        &.header-info {
                            strong {
                                text-align: right;                                
                            }
                            p {
                                text-align: right;   
                            }
                        }
                        
                        
                    }
                }
            }
            .sidebar-btn {
                span {
                    margin-right: 0;
                    margin-left: 16px;
                }
                img {
                    transform: rotate(180deg);
                }
                &.active {
                    img {
                        transform: rotate(0);
                    }
                }
                
            }
        }
        .header-actions {
            &.alone {
                padding-left: 0;
                padding-right: 32px;
            }
            ul {
                li {
                    &:not(:last-child) {
                        margin-right: 0;
                        margin-left: 32px;
                    }
                }
                
            }
            .request-btn {
                border-top-right-radius: 25px;
                border-bottom-right-radius: 25px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;

            }
        }
        .header-nested-actions {
            padding-right: 0;
            padding-left: 66px;
            @media (max-width: 1281px) {
                padding-left: 16px;
            }
        }
        
    }
}