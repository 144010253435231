.input-wrap {
    position: relative;
    .input-icon {
        position: absolute;
        top: 17px;
        left: 16px;
        z-index: 1;
    }
    .remove-text {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 1;
        cursor: pointer;
    }
    .site-input {
        outline: 0;
        border: 0;
        height: 50px;
        width: 100%;
        border-radius: 25px;
        padding: 0 16px;
        box-shadow: 0 4px 10px 0 rgba(34, 43, 69, 0.1);
        background-color: #fff;
        font-size: 15px;
        font-family: 'Roboto';
        -webkit-appearance: none;
    }
    &.hasSearchIcon {
        .site-input {
            padding-left: 46px;
            padding-right: 46px;
        }
    }
    &.has-input-icon {
        .input-icon {
            top: 12.5px;
            width: 14px;
            max-height: 15px;
    
        }
        .site-input {
            padding-left: 42px;
            padding-right: 42px;
        }
    }
    &.has-eye-icon {
        .remove-text {
            top: 12.5px;
            right: 16px;
            width: 20px;
            height: 18px;
        }
    }
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

html[dir=rtl] {
    .input-wrap {
        .input-icon {
            left: auto;
            right: 16px;
        }
        .site-input {
            font-family: 'Almari';
        }
        .remove-text {
            right: auto;
            left: 20px;
        }

        &.has-eye-icon {
            .remove-text {
                right: auto;
                left: 16px;
            }
        }
    
    }


}