.class1{
    border: none;
  padding: 10px;
  .react-calendar__navigation {
    margin-bottom: 0px;
    button:nth-child(3){
        background:white;
    }
    button:nth-child(1){
      display: none;
    }
    button:nth-child(5){
        display: none;
      }
    .react-calendar__navigation__label {
    font-size: 14px;
    }
  
}

.react-calendar__tile--now ,
.react-calendar__tile--hasActive {
       
 background-color: white;
}

.react-calendar__navigation button{
   
    border-radius: 25px;
    background-color: rgba(34, 43, 69,.05);
    }


.react-calendar__tile {
    font-size: 14px;
    color:#7a808f;
    height: 20px;
    border-bottom: solid 1px rgba(151, 151, 151,0.2);
}

.react-calendar__month-view__weekdays__weekday {
    font-size: 14px;
    color:#7a808f;
    
}

.react-calendar__tile--active {
    background: rgba(73, 196, 110, 0.7);
    color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #49c46e;
}

.react-calendar__tile,
.react-calendar__month-view__days__day {
    font-size: 14px;
    
    padding: 20px 0px;
    margin:5px 0px;
}
}