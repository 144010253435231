.login-container {
  height: 100%;
  width: 100%;

  .login-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    .login-form {
      width: 689px;
      padding: 50px;
      position: relative;
      .form-nav-list {
        .header-info{
          font-size: 18px;
              font-weight: 500;
              color: #2ebb55;
        }
        ul {
          list-style-type: none;
          display: flex;
          align-items: center;
          margin: 0;
          padding: 0;
          height: 100%;
          li {
            position: relative;
            &:not(:last-child) {
              padding-right: 16px;
              margin-right: 16px;

           
              &:after {
                content: "";
                position: absolute;
                right: 0;
                top: 50%;
                margin-top: -15px;
                width: 1px;
                height: 30px;
                background-color: #222b45;
                opacity: 0.1;
              }
            }
            &.brand-logo {
              svg {
                width: 50px;
                height: 50px;
              }
              @media (min-width: 1420px) {
                svg {
                    width: 80px;
                    height: 80px;
                }
              } 
            }
          }
        }
      }
      .form-container {
        width: 80%;
        margin: 50px auto;
        .form-header {
          text-align: center;
          .welcome-message {
            font-size: 36px;
            font-weight: bold;
            color: #485670;
          }
          .login-message {
            font-size: 24px;
            font-weight: 500;
            color: #7a808f;
          }
        }
        .inputs-container {
          text-align: center;
          margin-top: 80px;
          .form-row {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 30px;
            .form-col {
              width: 100%;
              strong {
                display: block;
                margin-bottom: 8px;
                color: #222b45;
                font-size: 14px;
                text-align: start;
              }
              .input-wrap {
                position: relative;
                p{
                  color: red;
                  font-size: 12px;
                  text-align: start;
                  margin-top: 5px;

                }
              }
              .site-input {
                height: 40px;
                border-radius: 5px;
                box-shadow: none;
                border: 1px solid #dfe6ee;
              }

              .red-input{
                border: 1px solid red;
              }
            }
          }
          .check-wrapper {
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
            flex-wrap: wrap;
            .remember-me-text {
              color: rgba 22 32 53, 0.9;
              font-size: 14px;
              margin-left: 5px;
              display: block;
            }
            .forget-pass-text {
              color: #44c267;
              font-size: 16px;
              font-weight: 500;
              text-decoration: underline;
            }
          }
          .button-container {
            button {
              width: 100%;
              margin: 50px 1px 0 0;
              padding: 20px;
              border-radius: 25px;
              background-color: #44c267;
              color: #fff;
              font-size: 16px;
              border: none;
            }
          }
          .input-left-icon {
            position: absolute;
            top: 30%;
            left: 2%;
          }
          .input-right-icon {
            position: absolute;
            top: 30%;
            left: 94%;
            cursor: pointer;
          }
        }
        .checkbox-container {
          display: flex;
          flex-wrap: wrap;
          .checkmark {
            display: inline-block;
            width: 20px;
            height: 20px;
            background-color: #ddd;
            border-radius: 5px;
            position: relative;
            transition: background-color 0.4s;
          }

          #check:checked ~ .checkmark {
            background-color: #08bb68;
          }

          .checkmark::after {
            content: "";
            position: absolute;
            width: 5px;
            height: 15px;
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%) rotateZ(40deg) scale(5);
            opacity: 0;
            transition: all 0.4s;
          }
          #check:checked ~ .checkmark::after {
            opacity: 1;
            transform: translate(-50%, -50%) rotateZ(40deg) scale(1);
          }
        }
        .form-errors {
          width: 100%;
          height: 50px;
          background-color: #ffe0e0;
          padding: 10px;
          display: flex;
          align-items: center;

          span{
         
            font-size: 14px;
            font-weight: 500;
            color: rgba (22 32 53, 0.9);
            margin-inline-start: 5px;
          }
        
        }
      }
      .form-footer {
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 0px;
        width: 80%;
        height: 60px;
        .footer-text {
          font-size: 14px;
          color: #313541;
        }
      }
    }
    .otp-container{
      width: 80%;
      margin: 150px auto;
      text-align: center;
      p:nth-child(1){
      
        font-size: 36px;
        color: #485670;
        font-weight: bold;
      }
      p:nth-child(2){
      
        font-size: 18px;
        color: #7a808f;
        width: 60%;
        margin: 0 auto;
        margin-bottom: 30px;
      }
      p:nth-child(3){

        font-size: 16px;
        font-weight: 500;
        color: #485670;
        text-decoration: underline;
      }
      .counter{
        
        font-size: 16px;
        font-weight: 500;
        color: #44c267;
      }
      .otp{
        margin: 0 auto !important;
        width: 60%;
      }
      .invalid-otp{
    
        font-size: 16px;
        font-weight: 500;
        color: #f97070;
    
      }
    }
    .login-imageContainer {
width: calc(100% - 689px);
    background: linear-gradient(to bottom, #485670e0, #485670d6), url(/static/media/login-image.97681a00.png);
    background-repeat: no-repeat;
      .imageContainer-content {
        padding: 130px;
        .imageContainer-greenTxt {
          font-size: 36px;
          font-weight: bold;
          color: #82cf97;
        }

        .imageContainer-whiteTxt {
          font-size: 24px;
          font-weight: 500;
          color: #ffffff;
          width: 57%;
        }
      }
      .imageContainer-route {
        max-width: 100%;
      }
    }
  }
}
