.map-setting-container {
    position: absolute;
    left: 121px;
    bottom: 16px;
    z-index: 1001;
    transition: left .2s ease-in-out;
    
    &.side-bar-open {
        left: 316px;
        
    }
    @media (max-width: 1281px) {
        left: 91px;
        &.side-bar-open {
            left: 266px;
            
        }
    }
    
    &.open {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        width: 100%;
        height: 100%;
        padding: 111px 16px 16px 121px;
        background-color: rgba(34, 43, 69, 0.9);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        transition: background .2s ease-in-out;
        @media (max-width: 1281px) {
            padding-left: 91px;
        }
        &.side-bar-open {
            padding-left: 316px;
            @media (max-width: 1281px) {
                padding-left: 266px;
            }
            
        }
        
    }
    .map-setting-wrapper {
        background-color: #fff;
        border-radius: 5px;
        min-width: 400px;
        transition: all .3s ease-in-out;
        opacity: 0;
        transform: translateY(10px);
        &.open {
            opacity: 1;
            transform: translateY(0);
        }
    }
    
    
    .map-setting-key {
        margin-top: 8px;
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
        .map-setting-icon {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.07);
            margin-right: 8px;
        }
        span {
            font-size: 14px;
            font-weight: 900;
            color: #000;
            text-transform: capitalize;
            @media (max-width: 1281px) {
                display: none;
            }
            &.white {
                color: #fff;
            }
        }
    }

    .map-setting-head,
    .map-setting-body {
        padding: 16px 24px;
    }
    .map-setting-head {
        display: flex;
        align-items: center;
        background-color: #f5f5f6;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        img {
            margin-right: 8px;
        }
        strong {
            font-size: 14px;
        }
    }
    .map-setting-body {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .hide-ea-vehicles {
        display: flex;
        align-items: center;
        strong {
            font-size: 14px;
            color: #7a808f;
            margin-right: 8px;
        }
    }

    .map-setting-item-container {
        // padding-top: 16px;
        // margin-top: 16px;
        // border-top: 2px solid #f5f5f6;
        .map-setting-item-wrapper {
            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }
    }
    .map-setting-item-head {
        margin-bottom: 16px;
        strong {
            color: #222b45;
            font-size: 14px;
        }
    }
    .map-setting-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 16px;
        background-color: #f5f5f6;
        border-radius: 5px;
        &:not(:last-child) {
            margin-bottom: 4px;
        }

        .map-setting-item-text {
            strong {
                color: #7a808f;
                font-size: 12px;
            }
        }
    }
}


.custom-switch-wrap {
    width: 40px;
    height: 20px;
    border: 2px solid #7a808f;
    border-radius: 14px;
    position: relative;
    cursor: pointer;
    &.active {
        border-color: #44c267;
        .custom-switch-circle {
            border-color: #44c267;
            transform: translateX(20px);
        }
    }
    .custom-switch-circle {
        width: 10px;
        height: 10px;
        position: absolute;
        top: 3px;
        left: 3px;
        border: 2px solid #7a808f;
        border-radius: 100%;
        transition: transform .3s ease-in-out;
    }
}


html[dir=rtl] {
    .map-setting-container {
        left: auto;
        right: 121px;

        &.side-bar-open {
            left: auto;
            right: 316px;
            
        }

        @media (max-width: 1281px) {
            right: 91px;
            &.side-bar-open {
                right: 266px;
                
            }
        }
        &.open {
            right: 0;
            padding: 111px 121px 16px 16px;
            @media (max-width: 1281px) {
                padding-right: 91px;
            }
            &.side-bar-open {
                padding-left: 16px;
                padding-right: 316px;
                @media (max-width: 1281px) {
                    padding-right: 266px;
                }
            }

            
        }
        
        
        
        .map-setting-key {
            .map-setting-icon {
                margin-left: 8px;
                margin-right: 0;
            }
            
        }
    
        
        .map-setting-head {
            img {
                margin-right: 0;
                margin-left: 8px;
            }
        }
        .hide-ea-vehicles {
            strong {
                margin-right: 0;
                margin-left: 8px;
            }
        }
        
        
    
       
        
        
    }
    
    
    .custom-switch-wrap {
        
        &.active {
            .custom-switch-circle {
                transform: translateX(-20px);
            }
        }
        .custom-switch-circle {
            left: auto;
            right: 3px;
        }
    }
}