.sidebar-contain {
    width: 105px;
    height: calc(100% - 95px);
    background-color: #fff;
    box-shadow: 0 4px 3px 2px rgba(0, 0, 0, 0.07);
    position: fixed;
    top: 95px;
    left: 0;
    z-index: 1001;
    overflow: hidden;
    transition: all .3s ease-in-out;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    @media (max-width: 1281px) {
        top: 65px;
        height: calc(100% - 65px);
        width: 75px;
    }
    &.nested {
        left: 0;
        z-index: 999;
        border-top: 1px solid rgba(34, 43, 69, 0.05);
        overflow: unset;
        &.open {
            width: 278px;
            left: 105px;
            @media (max-width: 1281px) {
                left: 75px;
                width: 220px;
            }
            &.mainIsOpen {
                left: 300px;
                @media (max-width: 1281px) {
                    left: 250px;
                }
            }
        }
        &.mainIsOpen {
            left: 195px;
            @media (max-width: 1281px) {
                left: 175px;
            }
        }
        .sidebar-wrap {
            position: relative;
            z-index: 2;
            background-color: #fff;
            ul {
                li, a {
                    .text {
                        display: flex;
                        max-width: 100%;
                        width: 100%;
                        @media (max-width: 1281px) {
                            font-size: 14px;
                        }
                        
                        a {
                            width: 100%;
                            padding-left: 32px;
                        }
                    }
                }
            }
        }
    }
    .sidebar-close {
        position: absolute;
        top: 0;
        right: -50px;
        width: 50px;
        z-index: 1;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.07);
        background: #fff;
        cursor: pointer;
        transition: all .3s ease-in-out;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        img {
            transform: rotate(180deg);
            &.flip {
                transform: rotate(360deg);
            }
        }
    }
    &.open {
        width: 300px;
        @media (max-width: 1281px) {
            width: 250px;
        }
        .sidebar-wrap {
            ul {
                li, a {
                    .text {
                        display: flex;
                        max-width: 100%;
                    }
                }
            }
        }
    }
    .sidebar-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            li {
                opacity: 0.6;
                &.active {
                    opacity: 1;
                }
            }
            li, a {
                height: 105px;
                display: flex;
                align-items: center;
                flex: auto;
                position: relative;
                cursor: pointer;
                color: rgba(22, 32, 53, 0.9);
                font-weight: 500;
                text-decoration: none;
                @media (max-width: 1281px) {
                    height: 75px;
                }
                
                &:not(:last-child) {
                    border-bottom: 1px solid rgba($color: #222b45, $alpha: 0.05);
                }
                &.active {
                    background-color: rgba($color: #222b45, $alpha: 0.05);
                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 0;
                        z-index: 1;
                        width: 4px;
                        height: 100%;
                        background-color: #44c267;
                    }
                }
                svg {
                    width: 20px;
                    height: 20px;
                }

                .icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 105px;
                    height: 100%;
                    @media (max-width: 1281px) {
                        width: 75px;
                    }
                    &.flag {
                        flex-direction: column;
                        span {
                            font-size: 12px;
                            font-family: 'Almarai';
                            @media (max-width: 1281px) {
                                font-size: 10px;
                            }
                        }
                        svg {
                            width: 40px;
                            height: auto;
                            @media (max-width: 1281px) {
                                max-width: 34px;
                            }
                        }
                    }
                    
                }
                .text {
                    display: flex;
                    align-items: center;
                    transition: all 0.4s ease-in-out;
                    width: calc(100% - 105px);
                    display: none;
                    height: 100%;
                    max-width: 20px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    @media (max-width: 1281px) {
                        width: auto;
                    }
                    &.arabic {
                        font-family: 'Almarai', 'Segoe UI', sans-serif;
                    }
                    span {
                        text-transform: capitalize;
                        display: block;
                        line-height: 105px;
                        width: 100%;
                        height: 100%;
                        max-width: 170px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        @media (max-width: 1281px) {
                            line-height: 75px;
                            font-size: 14px;
                        }

                    }
                   
                }
                
                .user-icon {
                    width: 50px;
                    height: 50px;
                    border-radius: 100%;
                    background-color: #44c267;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media (max-width: 1281px) {
                        width: 36px;
                        height: 36px;
                        img {
                            max-width: 16px;
                        }
                    }
                }
                .notification-icon {
                    position: relative;
                    &.active {
                        &:after {
                            content: "";
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 12px;
                            height: 12px;
                            border-radius: 100%;
                            background-color: #44c267;
                        }
                    }
                }
            }
        }
        .sidebar-bot {
            ul {
                li {
                    opacity: 1;
                    .text {
                        opacity: 0.6;
                    }
                    &.active {
                        .text {
                            opacity: 1;
                        }
                    }
                    
                }
            }
        }
    }
}

html[dir=rtl] {
    .sidebar-contain {
        left: auto;
        right: 0;

        &.nested {
            left: auto;
            right: 0;
            &.open {
                left: auto;
                right: 105px;
                @media (max-width: 1281px) {
                    right: 75px;
                }
                &.mainIsOpen {
                    left: auto;
                    right: 300px;
                    @media (max-width: 1281px) {
                        right: 250px;
                    }
                }
            }
            &.mainIsOpen {
                left: auto;
                right: 195px;
                @media (max-width: 1281px) {
                    right: 175px;
                }
            }
            .sidebar-wrap {
                ul {
                    li, a {
                        .text {
                            
                            a {
                                padding-left: 0;
                                padding-right: 32px;
                            }
                        }
                    }
                }
            }
        }
        .sidebar-close {
            right: auto;
            left: -50px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
        
            img {
                transform: rotate(360deg);
                &.flip {
                    transform: rotate(180deg);
                }
            }
        }
        
        .sidebar-wrap {
            ul {
                li, a {
                    &.active {
                        &:after {
                            right: auto;
                            left: 0;
                        }
                    }
                    
    
                    .icon {
                        &.flag {
                            span {
                                font-family: 'Roboto';
                            }
                        }
                        
                    }
                    
                    
                    .notification-icon {
                        &.active {
                            &:after {
                                right: auto;
                                left: 0;
                            }
                        }
                    }
                }
            }
            
        }
    }
    
}