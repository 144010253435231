.general-card-contain {
    margin-top: 96px;
    .general-card-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .general-card {
        background-color: #fff;
        box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.09);
        min-width: 593px;
        max-width: 593px;
        min-height: 538px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .general-card-icon {
        background: #fb8888;
        width: 80px;
        height: 80px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .general-card-body {
        margin-top: 32px;
        text-align: center;
        .site-btn {
            min-width: 202px;
        }
    }
}