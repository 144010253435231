.notification-wrapper {
    position: fixed;
    width: auto;
    left: 50%;
    bottom: 0;
    margin-left: -240px;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    &.radius-map {
        position: absolute;
    }
    @media (max-width: 1281px) {
        display: none;
    }
    .notification-parent {
        width: 100%;
        min-width: 28.063rem;
        max-width: 28.063rem;
        display: flex;
        &.green {
            .notification-icon {
                background-color: #2ebb55;
            }
            .notification-text {
                background-color: rgba(226, 241, 230, 0.95);
            }
        }
        &.yellow {
            .notification-icon {
                background-color: #f5be62;
            }
            .notification-text {
                background-color: #f0e6b5;
            }
        }
        .notification-icon {
            width: 10%;
            min-height: 50px;
            border-top-left-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            
        }
        .notification-text {
            width: 90%;
            border-top-right-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
           
            p {
                padding: 8px 0 8px 16px;
                margin: 0;
                font-size: 14px;
                line-height: 1;
                
            }
        }
        .close-notification {
            height: 100%;
            padding: 0 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }
    
}


html[dir=rtl] {
    .notification-wrapper {
        .notification-parent {
           
            .notification-icon {
                border-top-right-radius: 20px;
                border-top-left-radius: 0;
                
            }
            .notification-text {
                border-top-left-radius: 20px;
                border-top-right-radius: 0;
               
                p {
                    padding: 8px 16px 8px 0;
                    
                }
            }
            
        }
        
    }
    
}