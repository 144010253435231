@media print {
  body * {
    visibility: hidden;
  }
  #section-to-print * {
    visibility: visible;

  }

}

.reports-container {
  .portal-table-contain {
    @media (max-width: 1281px) {
      margin-top: 0;
    }
  }
  .rt-th {
    padding: 0 !important;
  }
  .rt-td {
    text-align: left;
    padding: 14px 16px;
    font-size: 16px;
    @media (max-width: 1281px) {
      font-size: 14px;
    }
  }
  .rt-tr.-even {
    background-color: #f4f7fa;
  }
  .rt-resizable-header-content {
    font-weight: 500;
    text-align: left;
    padding: 14px 16px;
    font-size: 18px;
    @media (max-width: 1281px) {
      font-size: 15px;
    }

  }
  .ReactTable .rt-thead.-header {
    box-shadow: unset;
  }
  .ReactTable {
    border: 0;
  }
  .pagination-bottom {
    margin-top: 32px;
  }
}

.reports-table-head {
  background-color: #fff;
  padding: 32px 48px;
  padding-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 1281px) {
    padding: 16px;
  }
  .reports-table-head-title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 8px;
    .reports-table-head-title-icon {
      margin-right: 8px;
      display: flex;
      align-items: center;
      img {
        margin-right: 8px;
      }
      strong {
        color: #485670;
        font-size: 20px;
        margin-bottom: 0;
        padding-bottom: 0;
  
        span {
          color: #44c267;
          margin-bottom: 0;
          padding-bottom: 0;
    
        }
      }
    }
    p {
      font-size: 14px;
      color: #7a808f;
      margin-bottom: 0;
      padding-bottom: 0;
      font-weight: 500;
    }
  }
  .reports-table-head-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    .reports-table-head-actions-item {
      margin-right: 16px;
      height: 40px;
      min-width: 139px;
      background-color: #fff;
      box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.07);
      color: #4b5268;
      position: relative;
      user-select: none;
      &.reset {
        background-color: #fff6f6;
        box-shadow: none;
        color: #ff5757;
      }
      .reports-dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 99;
        width: 100%;
        background-color: #fff;
        box-shadow: 0 2px 6px 0 #b0bac5;
        border-radius: 4px;
        margin-top: 6px;
        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
          li {
            padding: 12px 16px;
            display: flex;
            align-items: center;
            &:not(:last-child) {
              border-bottom: 1px solid rgba(72, 86, 112, 0.1);
            }
            img {
              margin-right: 8px;
            }
            span {
              font-size: 14px;
              color: #313541;
              font-weight: 400;
            }
          }
        }
      }
      &.reset-data{

        background-color: #fff6f6;
        box-shadow:none;
      }
      
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.report-head-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .reports-search-wrap {
    width: 60%;
    padding-right: 20px;
  }
  .reports-search-btn-wrap {
    width: 10%;
    background: #FFF;
    margin-right: 15px;
    display: flex;
    margin-top: 32px;
    height: 38px;
    box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.07);
    background-color: #fff;
    color: #4b5268;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .reports-date-wrap {
    width: 30%;
    .MuiIconButton-root {
      padding: 0;
    }
    .site-input {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > span {
      display: block;
      max-width: 80%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: #485670;
      font-weight: 500;
      font-size: 14px;
      }
    }
    .tabs-requests-container {
      top: 100%;
      right: 0;
      left: auto;
    }
  }
  strong {
    color: #222b45;
    font-size: 14px;
    display: block;
    margin-bottom: 12px;
  }
  .fake-input {
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: solid 1px #dfe6ee;
  }
}


html[dir="rtl"] {
  .reports-table-head {
    .reports-table-head-title {
      .reports-table-head-title-icon {
        margin-right: 0;
        margin-left: 8px;
        img {
          margin-right: 0;
          margin-left: 8px;
        }
      }
    }
    .reports-table-head-actions {
      .reports-table-head-actions-item {
        margin-right: 0;
        margin-left: 16px;
      }
    }
  }
  .report-head-wrap {
    .reports-date-wrap {
      .tabs-requests-container {
        right: auto;
        left: 0;
      } 
    }
  }
  .reports-container {
    .rt-td,
    .rt-resizable-header-content {
      text-align: right;
    }
  }
}
