.dashboard-loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.dashboard-error {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  h1 {
    position: relative;
  }
}

.pickerInput {
  height: 40px;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  border: solid 1px #dfe6ee;
  padding: 0px 20px;
  align-items: center;
}
.tabs-requests-container {
  position: absolute;
  display: block;
  left: 72%;
  z-index: 1000;
  box-shadow: 0px 0px 12px 4px #e9ecef;
  background-color: #fff;
}
.body {
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  @media (max-width: 1281px) {
    padding: 0;
  }

  .header {
    .MuiIconButton-root {
      @media (max-width: 1064px) {
        padding: 10px;
      }
    }
    border-radius: 15px 15px 0px 0px;
    background-color: #222b45;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 8px 16px;
    .header-left {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex: auto;
      @media (max-width: 1281px) {
        justify-content: space-between;
      }
    }
    .header-right {
      flex: auto;
      display: flex;
      justify-content: flex-end;
    }
    position: relative;

    .calendar-container {
      position: absolute;
      top: 100%;
      margin-top: -12px;
      right: 16px;

      .tabs-container {
        border-radius: 10px;
        padding: 10px;
        background-color: white;
        box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.1);
        outline: 0;
        width: 330px;
        z-index: 100;
      }

      .tabs {
        border-bottom: solid 1px rgba(151, 151, 151, 0.2);
        outline: 0;
      }
    }

    .date-rectangle {
      margin: 16px;
      padding: 10px 20px;
      display: flex;
      border-radius: 60px;
      box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.1);
      background-color: white;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      color: #485670;
      font-weight: 500;

      @media (max-width: 1330px) {
        flex: auto;
      }
      @media (max-width: 1064px) {
        margin: 10px 6px;
        padding: 10px 16px;
        font-size: 16px;
        height: 50px;
        padding-right: 8px;
      }
    }

    .circle-BG {
      align-items: center;
      display: flex;
      justify-self: center;
      border-radius: 25px;
      background-color: #f0f2f5;
    }

    .rectangle {
      width: 167px;
      height: 5px;
      margin: 5px 0 5px 0;
      border-radius: 5px;
      background-color: #151d2d;
      @media (max-width: 1281px) {
        width: 100%;
      }
    }
    .cancel-rectangle {
      width: 48px;
      height: 5px;
      border-radius: 5px;
      background-image: linear-gradient(87deg, #d55b5b, #fb8888 86%);
    }

    .complete-rectangle {
      width: 122px;
      height: 5px;
      border-radius: 5px;
      background: linear-gradient(90deg, #346072 0%, #71bbd9 0%, #346072 100%);
      &.green {
          background: unset;
          background-color: #00d78c;
      }
      &.blue {
        background: unset;
        background-color: #00c4e5;
      }
    }

    .status-div {
      margin: 16px;
      padding-right: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right-style: solid;
      border-right-width: 1px;
      flex: auto;

      border-image-source: linear-gradient(
        to bottom,
        #151d2d,
        #808aa3 53%,
        #151d2d 99%
      );
      border-image-slice: 1;
      @media (min-width: 1366px) and (max-width: 1367px) {
        margin: 2px 0;
      }
      @media (max-width: 1281px) {
        border: 0;
        margin: 12px;
        padding-right: 0px;
        flex: unset;
      }
      @media (max-width: 1064px) {
        margin: 10px 6px;
      }
      &.cancel {
        border: none;
      }

      .Oval {
        margin-inline-end: 16px;
        color: white;
        font-size: 24px;
        font-weight: bold;
        width: 100px;
        height: 100px;
        border-radius: 100%;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          top: -3px;
          right: -3px;
          z-index: 1;
          width: 106px;
          height: 106px;
          background: rgb(113, 187, 217);
          background: linear-gradient(
            90deg,
            rgba(113, 187, 217, 1) 0%,
            rgba(113, 187, 217, 1) 0%,
            rgba(72, 197, 107, 1) 100%
          );
          border-radius: 100%;
        }
        &.gray {
          &:after {
            background: unset;
            background-color: rgba($color: #56627f, $alpha: 0.9);
          }
        }
        @media (max-width: 1281px) {
          width: 71px;
          height: 71px;
          &:after,
          &:before {
            width: 77px;
            height: 77px;
          }
        }

        @media (max-width: 1064px) {
          width: 50px;
          height: 50px;
          &:after,
          &:before {
            width: 56px;
            height: 56px;
          }
        }
      }
      .Oval-in {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #222b45;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        @media (max-width: 1281px) {
          font-size: 16px;
        }
        @media (max-width: 1064px) {
          font-size: 14px;
        }
      }
      .request-labels {
        justify-content: center;
        display: flex;
        flex-direction: column;
        color: white;
        flex: auto;
        padding-right: 16px;
        span {
          font-weight: 500;
          font-size: 18px;
          @media (max-width: 1064px) {
            font-size: 14px;
          }
        }
        label {
          font-weight: normal;
          font-size: 14px;
          color: white;
          line-height: 1.5;
          margin-bottom: 0;
          @media (max-width: 1064px) {
            font-size: 12px;
          }

          &.total {
            color: #bec8da;
          }
        }
      }

      .small-Oval {
        margin-inline-end: 16px;
        border-radius: 50%;
        color: white;
        font-size: 18px;
        font-weight: 500;
        width: 71px;
        height: 71px;
        position: relative;
        &.cancel {
          &:after {
            background: #d65c5c;
            background: linear-gradient(
              90deg,
              #d65c5c 0%,
              #fb8888 0%,
              #d65c5c 100%
            );
          }
        }
        &:after {
          content: "";
          position: absolute;
          top: -3px;
          right: -3px;
          z-index: 1;
          width: 77px;
          height: 77px;
          background: rgb(113, 187, 217);
          background: linear-gradient(
            90deg,
            rgba(113, 187, 217, 1) 0%,
            rgba(113, 187, 217, 1) 0%,
            rgba(51, 96, 114, 1) 100%
          );
          border-radius: 100%;
        }
        &.green {
          &:after {
            background: unset;
            background-color: #00d78c;
          }
        }
        @media (max-width: 1064px) {
          width: 50px;
          height: 50px;
          &:after,
          &:before {
            width: 56px;
            height: 56px;
          }
        }
      }
    }
  }
  .chart-cards {
    display: flex;
    flex-direction: column;

    .total-card {
      padding: 20px;
      border-radius: 0px 0px 15px 15px;
      box-shadow: 4px 5px 13px 2px rgba(0, 0, 0, 0.07);
      @media (max-width: 1281px) {
        padding: 12px;
      }
    }

    .card {
      margin: 40px 0px;
      padding: 30px 20px 20px 20px;
      border-radius: 15px;
      box-shadow: 4px 5px 13px 2px rgba(0, 0, 0, 0.07);
      @media (max-width: 1281px) {
        padding: 12px;
      }
      .chart-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        .site-tabs {
          display: flex;
          flex: auto;
          justify-content: flex-end;
          margin-top: 16px;
          margin-bottom: 16px;
        }
      }
      .type-chart {
        display: flex;
        &.true {
          margin-inline-end: 50px;
        }
        .offset {
          cursor: pointer;
          &.true {
            z-index: 2;
            color: #6fbcd4;
            box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.1);
            background-color: rgba(255, 255, 255, 0.95);
            text-align: center;
            justify-content: center;
          }
          &.false {
            color: #485670;
            margin-right: -48px;
            border: solid 1px #d6d6d6;
            background-color: #f0f2f5;
            text-align: start;
            padding: 0px 20px;
            justify-content: flex-start;
          }
          margin-right: -48px;
          width: 145px;
          height: 50px;
          display: flex;
          align-items: center;
          border-radius: 65px;
          font-size: 16px;
          font-weight: 500;
        }

        .impound {
          font-size: 16px;
          font-weight: 500;
          cursor: pointer;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          &.true {
            color: #6fbcd4;
            z-index: 2;
            margin-right: -48px;
            box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.1);
            background-color: rgba(255, 255, 255, 0.95);
          }
          &.false {
            color: #485670;
            padding: 0px 20px;
            border: solid 1px #d6d6d6;
            background-color: #f0f2f5;
          }

          text-align: center;
          justify-content: center;
          width: 145px;
          height: 50px;
          border-radius: 65px;
        }
      }
      .title {
        min-height: 140px;
        vertical-align: middle;
        align-items: center;
        display: flex;
      }
      span {
        font-size: 24px;
        color: #485670;
        font-weight: bold;
        align-self: center;
      }
    }
  }
  .map-stat-card {
    width: 100%;
    margin-bottom: 30px;
    padding: 50px 30px 30px;
    border-radius: 15px;
    box-shadow: 4px 5px 13px 2px rgba(0, 0, 0, 0.07);
    background-color: #fff;
    strong {
      font-size: 24px;
      color: #485670;
      font-weight: bold;
      align-self: center;
    }
  }
  .stat-item {
    background-color: #f0f2f5;
    display: flex;
    justify-content: space-between;
    padding: 18px 28px;
    flex-direction: row;
    margin-bottom: 5px;

    .arrow-up {
      margin-top: 10px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
    }

    .arrow-down {
      margin-top: 10px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 10px solid #b7e2f6;
    }
    .dot {
      margin-top: 10px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #6d8cb8;
    }
    &.high {
      .arrow-up {
        border-bottom: 10px solid #415994;
      }
      #myBar {
        background-color: #415994;
      }
      .status-text {
        color: #415994;
      }
    }
    &.mid {
      .arrow-up {
        border-bottom: 10px solid #6d8cb8;
      }
      #myBar {
        background-color: #6d8cb8;
      }
      .status-text {
        color: #6d8cb8;
      }
    }

    &.low {
      .arrow-up {
        border-bottom: 10px solid #7bcbf8;
      }
      #myBar {
        background-color: #7bcbf8;
      }
      .status-text {
        color: #7bcbf8;
      }
    }
  }

  .map-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 611px;
    margin-top: 15px;
    .map {
      width: 100%;
    }
    .stat {
      width: 0%;
      margin-left: 30px;

      p {
        margin-bottom: 40px;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }
      .stat-name {
        display: flex;
      }
      .name {
        padding: 0px 20px;
      }
      .nameStyle {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        color: #4f566b;
        margin: 0px;
      }
      .requestStyle {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #7b8698;
        margin: 0px;
      }
      #myProgress {
        min-width: 167px;
        background-color: #d3d5da;
        border-radius: 20px;
      }

      #myBar {
        width: 70%;
        height: 5px;
        border-radius: 20px;
      }
      .status-contain {
        display: flex;
        justify-content: space-between;

        .status-text {
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          margin: 0px;
        }
      }
    }
  }

  .stat-bar-contain {
    width: 100%;
    height: 148px;
    margin: 30px 0 0;
    border-radius: 33px;
    border: solid 1px #dfdfdf;
    .text-bar {
      max-width: 795px;
      height: 20px;
      margin: -4% auto;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      position: relative;
      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .short-text {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #222b45;
      }
      .long-text {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #7a808f;
      }
    }

    .stat-bar {
      width: 795px;
      height: 20px;
      margin: 64px auto;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      background-image: linear-gradient(to right, #7fd3ff 0%, #384d8c 100%);
      position: relative;
      .lowBall {
        background-color: #7fd3ff;
        width: 20px;
        height: 20px;
        border: solid 4px white;
        border-radius: 50%;
      }
      .midBall {
        background-color: #6d8cb8;
        width: 30px;
        height: 30px;
        border: solid 4px white;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        bottom: -5px;
      }
      .highBall {
        background-color: #415994;
        width: 20px;
        height: 20px;
        border: solid 4px white;
        border-radius: 50%;
      }
    }
  }
}

html[dir="rtl"] {
  .body {
    .header {
      .calendar-container {
        right: auto;
        left: 16px;
      }

      .status-div {
        border-right: 0;
        border-left-style: solid;
        border-left-width: 1px;
        border-image-source: linear-gradient(
          to bottom,
          #151d2d,
          #808aa3 53%,
          #151d2d 99%
        );
        border-image-slice: 1;
        @media (max-width: 1281px) {
          border: 0;
        }
      }
      .request-labels {
        padding-right: 0;
        padding-left: 16px;
      }
    }
    .stat-bar-contain{
      .text-bar{
        flex-direction: row-reverse;
      }
    } 
    
  }
}
