.request-head-single {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        li {
            &:not(:last-child) {
                margin-right: 8px;
            }
        }

    }
    .request-title {
        justify-content: flex-start !important;
        margin-top: 16px;
        strong {
            margin-right: 16px !important;
        }
        span {
            position: relative;
            padding-right: 8px;
            padding-left: 20px;
            
            &:before {
                content: "";
                position: absolute;
                top: 5px;
                left: 0;
                z-index: 1;
                width: 12px;
                height: 12px;
                border-radius: 100%;
                background-color: rgba(68, 194, 103, 0.32);
            }
            &:after {
                content: "";
                position: absolute;
                top: 7px;
                left: 2px;
                z-index: 2;
                width: 8px;
                height: 8px;
                border-radius: 100%;
                background-color: #44c267;
            }


            

            &.pending {
                color: #c89f5a;
                &:before {
                    background-color: rgba(245, 190, 98, 0.3);
                }
                &:after {
                    background-color: #f5be62;
                }
            }

            &.onProgess {
                color: #44c267;
                &:before {
                    background-color: #c0e8cc;
                }
                &:after {
                    background-color: #44c267;
                }
            }

            &.completed {
                color: #71bbd9;
                &:before {
                    background-color: #d1e7f0;
                }
                &:after {
                    background-color: #71bbd9;
                }
            }

            &.cancelled {
                color: #fb8989;
                &:before {
                    background-color: #fad8d8;
                }
                &:after {
                    background-color: #fb8989;
                }
            }
            

            
        }
    }
    .request-head-single-data {
        ul {
            li {
                padding: 3px 22px;
                border-radius: 12.5px;
                font-size: 14px;
                font-weight: 500;
                @media (max-width: 1281px) {
                    font-size: 12px;
                }
                &.selected-id {
                    background-color: #485670;
                    color: #fff;
                }
                &.selected-status {
                    background-color: #fff;
                    color: #485670;
                }
            }
        }
    }
    .request-head-single-action {
        ul {
            li {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                user-select: none;
                cursor: pointer;
                border-radius: 100%;
                background-color: #fff;
                box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.07);
                
            }
        }
    }
}

.request-data-contain {
    .progress {
        height: auto;
        line-height: normal;
        background: none;
    }

    &:not(:first-child) {
        margin-top: 32px;
    }

    .request-data-head {
        margin-bottom: 22px;
        @media (max-width: 1281px) {
            margin-bottom: 14px;
        }
        strong {
            color: #485670;
            font-size: 18px;
            font-weight: 500;
            text-transform: capitalize;
            @media (max-width: 1281px) {
                font-size: 15px;
            }
        }
    }

    .request-data-body {
        .request-data-list {
            list-style-type: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            li {
                width: calc(50% - 4px);
                margin-bottom: 8px;
                border-radius: 4px;
                background-color: rgba($color: #f0f2f5, $alpha: 0.3);
                padding: 16px 12px;
                display: flex;
                font-weight: 500;
                @media (max-width: 1281px) {
                    padding: 9px;
                }

                .request-data-list-img {
                    margin-right: 13px;
                    position: relative;
                    top: -2px;

                    .request-data-list-img-icon {
                        width: 12px;
                        height: 12px;
                        border-radius: 100%;
                        background-color: #30bb55;
                        position: relative;
                        top: 6px;
                    }
                }

                .request-data-list-text {
                    flex: auto;
                    display: flex;
                    flex-direction: column;
                }

                &.full {
                    width: 100%;
                }

                &.auto-width {
                    width: auto;
                }

                &.noMargin {
                    margin-bottom: 0 !important;
                    padding-bottom: 0 !important;
                }
                &.address {
                    position: relative;
                    .copyLink {
                        position: absolute;
                        top: 8px;
                        right: 8px;
                        cursor: pointer;
                        display: block;
                        img {
                            max-width: 16px;
                        }
                        span {
                            position: absolute;
                            font-size: 11px;
                            width: 82px;
                            right: -5px;
                            top: -20px;
                            background: #44c26733;
                            text-align: center;
                            padding: 1px 0;
                            border-radius: 3px;
                            opacity: 0;
                            transition: all .3s ease-in-out;
                            &:after {
                                content: "";
                                position: absolute;
                                bottom: -5px;
                                right: 5px;
                                width: 0; 
                                height: 0; 
                                border-left: 5px solid transparent;
                                border-right: 5px solid transparent;
                                border-top: 5px solid #44c26733;
                            }
                        }
                    }
                }

                strong {
                    display: block;
                    font-size: 14px;
                    color: #7a808f;
                    text-transform: capitalize;
                    @media (max-width: 1281px) {
                        font-size: 13px;
                    }
                }

                p {
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    color: #4f566b;
                    margin: 4px 0 0 0;
                    word-break: break-word;
                    align-self: flex-start;
                    @media (max-width: 1281px) {
                        font-size: 14px;
                    }

                    .number {
                        width: 21px;
                        height: 21px;
                        margin-right: 8px;
                        border-radius: 100%;
                        background-color: #2ebb55;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                        font-size: 12px;
                    }
                }
            }
        }

        .recoveries-list {
            list-style-type: none;
            margin: 0;
            padding: 0;

            .recoveries-list-item {
                &.containActive {
                    margin-top: 32px;
                }
                &:not(:last-child) {
                    margin-bottom: 16px;
                }
                @media (max-width: 1281px) {
                    &:last-child {
                        margin-bottom: 180px;
                    }
                }

                border-radius: 4px;
                background-color: #fff;
                border: 1px solid rgba(22, 32, 53, 0.06);

            }

            .recoveries-head {
                padding: 16px 22px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-between;
                @media (max-width: 1281px) {
                    padding: 12px;
                }
                .recoveries-head-text-wrap {
                    display: flex;
                    align-items: center;

                    img {
                        margin-right: 16px;
                        @media (max-width: 1281px) {
                            display: none;
                        }
        
                    }

                    strong {
                        color: #485670;
                        font-size: 16px;
                        font-weight: 500;
                        @media (max-width: 1281px) {
                            font-size: 14px;
                        }
                    }

                    p {
                        margin: 0;
                        font-size: 16px;
                        color: #4f566b;
                        text-transform: capitalize;
                        font-weight: 500;
                        @media (max-width: 1281px) {
                            font-size: 14px;
                        }
                    }
                }

                .recoveries-head-status {
                    display: flex;
                    align-items: center;
                    .recoveries-head-status-text {
                        padding: 4px 14px;
                        background-color: #44c267;
                        color: #fff;
                        font-size: 14px;
                        border-radius: 15px;
                        margin-right: 22px;
                        height: auto;
                        line-height: normal;
                        @media (max-width: 1281px) {
                            font-size: 12px;
                        }

                        &.initiated {
                            background-color: #44c267;
                        }

                        &.completed {
                            background-color: #71bbd9;
                        }

                        &.cancelled {
                            background-color: #fb8888;
                        }

                        &.pending {
                            background-color: #c89f5a;
                        }
                    }

                    .recoveries-head-status-arrow {
                        background-color: rgba(34, 43, 69, 0.05);
                        width: 40px;
                        height: 40px;
                        border-radius: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        

                        img {
                            transform: rotateZ(90deg);
                            transition: all .3s ease-in-out;
                        }
                    }
                }
                &.active {
                    .recoveries-head-status-arrow {
                        img {
                            transform: rotateZ(-90deg);
                        }
                    }
                }
            }

            .recoveries-body {
                display: none;

                .recoveries-process-wrap {
                    padding: 32px 22px;
                    background-color: rgba($color: #f0f2f5, $alpha: 0.3);
                    position: relative;
                    ul {
                        margin: 0;
                        padding: 0;
                        list-style-type: none;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        position: relative;

                        &:after {
                            content: "";
                            position: absolute;
                            top: 13px;
                            right: 0;
                            z-index: 1;
                            height: 1px;
                            width: 100%;
                            border-top: 1px dashed #cecece;
                        }

                        .process-circle {
                            position: relative;
                            width: 26px;
                            height: 26px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: #e6e7ea;
                            border-radius: 100%;
                            z-index: 3;

                            &.completed {
                                background-color: #d1e7f0;

                                &:after {
                                    background-color: #71bbd9;
                                }
                            }

                            &.cancelled {
                                background-color: #fad8d8;

                                &:after {
                                    background-color: #fb8989;
                                }
                            }

                            img {
                                width: 11px;
                            }

                            &:after {
                                content: "";
                                position: absolute;
                                top: 3px;
                                right: 3px;
                                width: 20px;
                                height: 20px;
                                background-color: #babcc4;
                                border-radius: 100%;
                                z-index: -1;
                            }
                            // &:before {
                            //     content: "";
                            //     position: absolute;
                            //     top: 4px;
                            //     width: 17px;
                            //     height: 17px;
                            //     background-color: #fafbfc;
                            //     z-index: -2;
                            // }
                        }

                        li {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            position: relative;

                            &:after {
                                content: "";
                                position: absolute;
                                top: 5px;
                                height: 100%;
                                width: 50%;
                                background-color: #fafbfc;
                                z-index: 2;
                            }

                            &:first-child {
                                &:after {
                                    left: 0;
                                }

                                .process-circle {
                                    &:before {
                                        right: -18px;
                                    }
                                }
                            }

                            &:last-child {
                                &:after {
                                    right: 0;
                                }

                                .process-circle {
                                    &:before {
                                        left: -18px;
                                    }
                                }
                            }

                            strong {
                                font-size: 14px;
                                color: #babcc4;
                                margin-top: 4px;
                                position: relative;
                                z-index: 3;
                                @media (max-width: 1281px) {
                                    font-size: 12px;
                                }

                                &.completed {
                                    color: #222b45;
                                }

                                &.cancelled {
                                    color: #fb8989;
                                }
                            }

                            &.initiated {
                                .process-circle {
                                    background-color: #e6e7ea;

                                    &:after {
                                        background-color: #babcc4;
                                    }
                                }

                                strong {
                                    color: #babcc4;
                                }
                            }

                            &.completed {
                                .process-circle {
                                    background-color: #d1e7f0;

                                    &:after {
                                        background-color: #71bbd9;
                                    }
                                }

                                strong {
                                    color: #222b45;
                                }
                            }

                            &.cancelled {
                                .process-circle {
                                    background-color: #fad8d8;

                                    &:after {
                                        background-color: #fb8989;
                                    }
                                }
                                strong {
                                    color: #fb8989;
                                }
                            }

                            &.onProgess {
                                .process-circle {
                                    background-color: #c0e8cc;

                                    &:after {
                                        background-color: #44c267;
                                    }
                                }

                                strong {
                                    color: #222b45;
                                }
                            }

                            &.pending {
                                .process-circle {
                                    background-color: #e6e7ea;

                                    &:after {
                                        background-color: #babcc4;
                                    }
                                }

                                strong {
                                    color: #babcc4;
                                }
                            }
                        }
                    }
                    .time-spent-wrap {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: -23px;
                        .time-spent {
                            min-width: 137px;
                            padding: 8px 32px;
                            border-radius: 10px;
                            background-color: #fff;
                            box-shadow: 0 2px 6px 0 #b0bac5;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            line-height: 1;
                            span {
                                font-size: 10px;
                                font-weight: 500;
                                &:first-child {
                                    color: #485670;
                                    padding-right: 4px;
    
                                }
                                &:last-child {
                                    color: #2ebb55;
                                    
                                }
                            }
                        }
                    }
                    
                }

                .recoveries-body-list {
                    padding: 16px 22px;

                    .request-data-list {
                        li {
                            background-color: #fff;
                            padding: 0;
                        }
                    }

                    .recoveries-location-wrap {
                        .request-data-list {
                            position: relative;

                            &:after {
                                content: "";
                                position: absolute;
                                top: 27px;
                                left: 5px;
                                width: 1px;
                                height: calc(100% - 120px);
                                border-right: 1px dashed rgba(22, 32, 53, 0.08);
                            }
                        }

                        li {
                            .request-data-list-text {
                                padding-bottom: 16px;
                                margin-bottom: 16px;
                                border-bottom: 1px dashed rgba(22, 32, 53, 0.08);
                                flex: auto;
                                display: flex;
                                flex-direction: column;
                            }
                        }
                    }

                    .vehicle-data-wrap {
                        li {
                            &:not(:last-child) {
                                padding-bottom: 16px;
                                margin-bottom: 16px;
                            }
                        }

                        .vehicle-meta-wrap {
                            margin-top: 16px;

                            .vehicle-meta-item {
                                background-color: rgba(240, 242, 245, 0.35);
                                padding: 8px 22px;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                &:not(:last-child) {
                                    margin-bottom: 4px;
                                }

                                span {
                                    font-size: 14px;
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


.tracked-wrap {
    display: flex;
    margin-top: -14.5px;
    margin-left: -14.5px;
    .tracked {
        padding: 4px 10px;
        border-radius: 15px;
        background-color: #fff;
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.07);
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            font-size: 14px;
            color: #222b45;
            font-weight: 500;
            padding-left: 8px;
        }
    }
}



html[dir=rtl] {
    .request-head-single {
        ul {
            li {
                &:not(:last-child) {
                    margin-right: 0;
                    margin-left: 8px;
                }
            }
    
        }
        .request-title {
            strong {
                margin-right: 0 !important;
                margin-left: 16px !important;
            }
            span {
                padding-right: 0;
                padding-left: 0;
                padding-left: 8px;
                padding-right: 20px;


                &:before {
                    left: auto;
                    right: 0;

                }
                &:after {
                    left: auto;
                    right: 2px;
                }
    
            }
        }
    }
    
    .request-data-contain {
        .request-data-body {
            .request-data-list {
                li {
    
                    .request-data-list-img {
                        margin-right: 0;
                        margin-left: 13px;
                        img {
                            transform: scaleX(-1);
                        }
                        
                    }
                    &.address {
                        .copyLink {
                            right: auto;
                            left: 8px;
                            
                            span {
                                width: 82px;
                                right: auto;
                                left: -5px;
                                &:after {
                                    right: auto;
                                    left: 5px;
                                }
                            }
                        }
                    }
                    p {
                        .number {
                            margin-right: 0;
                            margin-left: 8px;
                        }
                        &.phoneNumber {
                            direction: ltr;
                        }
                    }
                }
            }
    
            .recoveries-list {
                .recoveries-head {
                    .recoveries-head-text-wrap {
                        img {
                            margin-right: 0;
                            margin-left: 16px;
                            transform: scaleX(-1);
                        }
                    }
                    .recoveries-head-status {
                        .recoveries-head-status-text {
                            margin-right: 0;
                            margin-left: 22px;
                        }
                    }
                }
                .recoveries-body {
                    .recoveries-process-wrap {
                        ul {
                            &:after {
                                right: auto;
                                left: 0;
    
                            }
                            .process-circle {
                                &:after {
                                    right: auto;
                                    left: 3px;
                                }
                            }
                            li {
                                &:first-child {
                                    &:after {
                                        left: auto;
                                        right: 0;
                                    }
                                    .process-circle {
                                        &:before {
                                            right: auto;
                                            left: -18px;
                                        }
    
                                    }
                                }
                                &:last-child {
                                    &:after {
                                        right: auto;
                                        left: 0;
                                    }
                                    .process-circle {
                                        &:before {
                                            left: auto;
                                            right: -18px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .recoveries-body-list {
                        .recoveries-location-wrap {
                            .request-data-list {
                                &:after {
                                    border-right: 0;
                                    border-left: 1px dashed rgba(22, 32, 53, 0.08);
                                }
                            }
                        }
                        
                    }
        
                }
            }
        }
    }

    .tracked-wrap {
        margin-left: 0px;
        margin-right: -14.5px;
        .tracked {
            span {
                padding-left: 0;
                padding-right: 8px;
            }
        }
    }
    
}
