.data-table-wrapper {
  overflow-x: auto;

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    td,
    th {
      padding: 14px 16px;
      color: #313541;
    }
    thead {
      tr {
        background-color: #fff;
        th {
          font-size: 18px;
          @media (max-width: 1281px) {
            font-size: 15px;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 16px;
          font-weight: 500;
          word-break: break-all;
          @media (max-width: 1281px) {
            font-size: 14px;
          }
          &:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }
          &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
        &:nth-child(odd) {
          background-color: #f4f7fa;
        }
      }
    }
  }
}
.table-loading-container {
  padding: 1rem;
  padding-top: 1.5rem;
  background-color: #f4f7fa;
  text-align: center;
}
.table-loading-container p {
  color: #777;
  font-size: 95%;
  font-weight: 500;
}
