.login-contain {
    height: 100%;
    width: 100%;
    .login-wrap {
        height: 100%;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .login-data-contain {
        height: 100%;
        width: 45%;
        max-width: 678px;
        padding: 50px;
        @media (max-width: 1281px) {
            width: 62%;
            padding: 20px;
        }
    }

    .login-img-contain {
        height: 100%;
        width: 55%;
        flex: auto;
        padding: 50px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        @media (max-width: 1281px) {
            padding: 20px;
        }
        @media (max-width: 1281px) {
            width: 38%;
        }
        .login-img-icon {
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%,-50%);
            max-width: 80%;
            max-height: 100%;
        }
        .login-img-text {
            strong {
                display: block;
                color: #82cf97;
                font-size: 32px;
                line-height: 1.3;
                @media (max-width: 1281px) {
                    font-size: 24px;
                }
            }
            p {
                margin-top: 16px;
                margin-bottom: 0;
                font-size: 24px;
                color: #fff;
                max-width: 576px;
                line-height: 1.3;
                @media (max-width: 1281px) {
                    font-size: 18px;
                }
                
            }
        }
    }

    .login-data-wrap {
        height: 100%;
        width: 100%;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        .login-data-head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            ul {
                display: flex;
                align-items: center;
                padding: 0;
                margin: 0;
                list-style-type: none;
                li {
                    padding-right: 14px;
                    margin-right: 14px;
                    position: relative;
                    svg {
                        width: 50px;
                        height: 50px;
                    }
                    strong {
                        font-size: 17px;
                        color: #2ebb55;
                        margin: 0;
                        padding: 0;
                        font-weight: 500;
                    }
                    &:not(:last-child) {
                        &:after {
                            content: "";
                            position: absolute;
                            right: 0;
                            z-index: 1;
                            top: 50%;
                            margin-top: -15px;
                            width: 1px;
                            height: 30px;
                            background-color: #222b45;
                            opacity: .1;
                        }
                    }
                }
            }
            .login-lang-wrap {
                .login-lang-item {
                    cursor: pointer;
                }
                .flag {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
                .flag-text {
                    font-size: 12px;
                    color: #222b45;
                    &.ar {
                        font-family: 'Almarai' !important;
                    }
                    &.en {
                        font-family: 'Roboto', "Helvetica", sans-serif !important;
                    }
                }
            }
        }
        .login-data-foot {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            p {
                font-size: 13px;
                color: #313541;
                opacity: 0.7;
                margin: 8px 0 0 0;
                padding: 0;
                &:first-child {
                    padding-right: 32px;
                }
                @media (max-width: 1281px) {
                    width: 100%;
                    padding: 0 !important;
                    text-align: center;
                }
            }
        }
    }
    .login-card {
        width: 100%;
    }
    
    .login-card-head {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        strong.title {
            margin-bottom: 8px;
            color: #485670;
            text-transform: capitalize;
            font-size: 36px;
            @media (max-width: 1281px) {
                font-size: 24px;
            }
        }
        p {
            padding: 0;
            margin: 0;
            margin-top: 16px;
            max-width: 281px;
            font-size: 18px;
            color: #7a808f;
            line-height: 1.3;
            @media (max-width: 1281px) {
                font-size: 14px;
            }
        }
        strong {
            display: block;
            margin: 0;
            padding: 0;
            line-height: 1.3;
            font-weight: 500;
            &:not(.title) {
                color: #7a808f;
                font-size: 24px;
                font-weight: 500;
                @media (max-width: 1281px) {
                    font-size: 16px;
                }
            }
        }
        span {
            display: block;
            font-size: 16px;
            color: #485670;
            padding: 0;
            margin: 0;
            margin-top: 16px;
            font-weight: bold;
            @media (max-width: 1281px) {
                font-size: 14px;
            }

        }
    }
    .login-data-body {
        max-width: 488px;
        margin: 0 auto;
        width: 100%;
        padding: 32px 0;
    }
    .login-card-body {
        margin-top: 48px;
    }
    .custom-checkbox-label {
        .custom-checkbox-text {
            font-size: 14px;
            color: #485670;
        }
        .custom-checkbox-checkmark {
            top: 5.5px;
        }
    }
    

}



html[dir="rtl"] {
    .login-contain {
       
        .login-card-head {
            span {
                direction: ltr;
    
            }
        }
        .login-data-wrap {
            .login-data-head {
                ul {
                    li {
                        padding-right: 0;
                        margin-right: 0;

                        padding-left: 14px;
                        margin-left: 14px;
                        &:not(:last-child) {
                            &:after {
                                right: auto;
                                left: 0;
                            }
                        }
                    }
                }
                
            }
            .login-data-foot {
                p {
                    &:first-child {
                        padding-right: 0;
                        padding-left: 32px;
                    }
                }
            }
        }
        
    }
    
    
}