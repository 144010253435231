//- layout
.request-report-wrap {
    padding-top: 32px;
    @media (max-width: 1281px) {
        padding-top: 0;
    }
    .request-report-data {
        background-color: #fff;
        box-shadow: 0 9px 21px 0 rgba(0, 0, 0, 0.07);
        border-radius: 5px;
    }
    .request-report-head {
        .head-level-1,
        .head-level-2 {
            padding: 32px;
            @media (max-width: 768px) {
                padding: 16px;
            }
        }
        .head-level-2 {
            background-color: #f7f8fa;
        }
        .head-level-1 {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .head-title {
            display: flex;
            
            img {
                margin-right: 8px;
                align-self: flex-start;
            }
            p {
                margin: 0;
                line-height: 1.2;
                span {
                    &:first-child {
                        font-size: 20px;
                        color: #485670;
                        font-weight: 500;
                        padding-right: 10px;
                    }
                    &:last-child {
                        font-size: 14px;
                        color: #7a808f;
                        @media (min-width: 1281px) and (max-width: 1392px) {
                            display: block;
                            padding-top: 4px;
                        }
                        @media (max-width: 992px) {
                            display: block;
                            padding-top: 4px;
                        }

                    }
                }
            }
        }
            
            
        .head-actions {
            position: relative;
            .actions-list {
                @media (min-width: 1282px) {
                    display: block !important;
                }
                @media (max-width: 1281px) {
                    display: none;
                    position: absolute;
                    top: 100%;
                    right: 0;
                    z-index: 10;
                    background-color: #fff;
                    box-shadow: 0 2px 6px 0 #b0bac5;
                    padding: 8px;
                    margin-top: 8px;
                }
                &.compare {
                    @media (max-width: 1281px) {
                        display: block !important;
                        position: static;
                        background-color: transparent;
                        box-shadow: unset;
                        padding: 0;
                        margin-top: 0;
                        .site-btn {
                            &.red {
                                height: 40px;
                                min-width: 139px;
                                padding: 0;
                                position: relative;
                                background-color: rgba(255, 87, 87, 0.1);
                                justify-content: center;
                            }
                        }
                    }
                }

                & > ul {
                    margin: 0;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    @media (max-width: 1281px) {
                        flex-direction: column;
    
                    }
                    li {
                        &:not(:last-child) {
                            margin-right: 16px;
                            @media (max-width: 1281px) {
                                margin-right: 0;
                                margin-bottom: 12px;
                            }
                        }
                        &.site-btn.white {
                            
                            @media (max-width: 1281px) {
                                justify-content: flex-start;
                                height: auto;
                                width: auto;
                                background-color: transparent;
                                box-shadow: none;
                            }
                        }
                        &.print-btn {
                            @media (max-width: 1281px) {
                                display: none;
                            }
                        }
                        
                    }
                }
                .reports-dropdown {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    z-index: 99;
                    width: 100%;
                    background-color: #fff;
                    box-shadow: 0 2px 6px 0 #b0bac5;
                    border-radius: 4px;
                    margin-top: 6px;
                    padding: 8px;
                    @media (max-width: 1281px) {
                        left: auto;
                        right: 100%;
                        top: 0;
                        margin-top: 0;
                        margin-right: 16px;
                    }
                    ul {
                        list-style-type: none;
                        margin: 0;
                        padding: 0;
                        li {
                            display: flex;
                            align-items: center;
                            margin-right: 0;
                            &:not(:last-child) {
                                margin-bottom: 12px;
                            }
                            
                            img {
                                margin-right: 8px;
                            }
                            span {
                                font-size: 14px;
                                color: #313541;
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
            .mobile-key {
                max-width: 139px;
                display: none;
                color: #fff;
                user-select: none;
                img {
                    width: auto;
                    max-width: 8px;
                    margin-right: 0;
                    margin-left: 8px;
                }
                @media (max-width: 1281px) {
                    display: flex;
                }
            }

            
            .site-btn {
                height: 40px;
                min-width: 139px;
                padding: 0;
                position: relative;
                &.white {
                    background-color: #fff;
                    box-shadow:  0 4px 10px 0 rgba(34, 43, 69, 0.1);
                    color: #4b5268;
                }
                &.red {
                    color: #ff5757;
                    background-color: rgba(255, 87, 87, 0.1);
                    @media (max-width: 1281px) {
                        justify-content: flex-start;
                        height: auto;
                        width: auto;
                        background-color: transparent;
                        box-shadow: none;
                    }
                }
                &.seperate {
                    padding: 0 32px;
                    @media (max-width: 1281px) {
                        padding: 0;
                        img {
                            align-self: flex-start;
                        }
                    }
                }
                
            }
        }
        
        .head-filter-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            .head-filter-location {
                width: 55%;
                padding-right: 16px;
                @media (max-width: 1124px) {
                    width: 100%;
                    padding-right: 0;
                    margin-bottom: 16px;
                }
            }
            .head-filter-date {
                width: 45%;
                display: flex;
                align-items: center;
                @media (max-width: 1124px) {
                    width: 100%;
                }
                @media (max-width: 768px) {
                    flex-wrap: wrap;
                }
                .site-input {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 40px;
                    .MuiIconButton-root {
                        padding: 0;
                    }
                    .date-text {
                        color: #485670;
                        font-size: 14px;
                        flex: auto;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        max-width: calc(100% - 25px);
                    }
                    
                }
                .tabs-requests-container {
                    top: 100%;
                    right: 0;
                    left: auto;
                    margin-top: 5px;
                }
            }
            .filter-location {
                & > strong {
                    display: block;
                    margin-bottom: 12px;
                    color: #222b45;
                    font-size: 14px;
                }
            }
            .filter-date {
                margin-right: 16px;
                flex: auto;
                & > strong {
                    display: block;
                    margin-bottom: 12px;
                    color: #222b45;
                    font-size: 14px;
                }
                @media (max-width: 768px) {
                    margin-right: 0;
                    margin-bottom: 16px;
                }
            }
            .filter-advanced {
                display: flex;
                align-items: center;
                align-self: flex-end;
                @media (max-width: 768px) {
                    width: 100%;
                }
                .filter-btn {
                    height: 40px;
                    margin-right: 16px;
                    min-width: 150px;
                    &.disabled {
                        opacity: 0.5;
                    }
                    @media (max-width: 768px) {
                        flex: auto;
                    }
                }
                .filter-advanced-key {
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    background-color: #fff;
                    box-shadow: 0 4px 10px 0 rgba(34, 43, 69, 0.1);
                    display: flex;
                    align-self: center;
                    justify-content: center;
                    cursor: pointer;
                    user-select: none;
                    img {
                        align-self: center;
                        transform: rotate(90deg);
                        transition: all .3s ease-in-out;
                    }
                    &.active {
                        img {
                            transform: rotate(-90deg);
                        }
                    }
                }
            }

            
        }
        .advanced-filter-contain {
            margin-top: 16px;
            & > strong{
                display: block;
                margin-bottom: 12px;
                color: #222b45;
                font-size: 14px;
            }
            .advanced-filter-wrap {
                display: flex;
                align-items: center;
                @media (max-width: 768px) {
                    flex-wrap: wrap;
                }
                .advanced-filter-select {
                    margin-right: 16px;
                    @media (max-width: 768px) {
                        width: 100%;
                        margin-right: 0;
                        margin-bottom: 16px;
                        
                    }
                    .request-select {
                        .request-select-body {
                            display: block;
                        }
                        .request-select-head {
                            width: 165px;
                            border-radius: 25px;
                            background-color: #fff;
                            box-shadow: 0 4px 10px 0 rgba(34, 43, 69, 0.1);
                            border: 0;
                            @media (max-width: 768px) {
                                width: 100%;
                            }
                            .request-select-text {
                                span {
                                    max-width: 113px;
                                }
                            }
                        }
                    }
                }
                .advanced-filter-input {
                    @media (max-width: 768px) {
                        width: 100%;
                    }
                    .site-input {
                        height: 40px;
                    }
                }
            }
        }
    }
    .request-report-body {
        padding: 32px;
        @media (max-width: 768px) {
            padding: 16px;
        }
        .request-report-map {
            margin-bottom: 32px;
            height: 450px;
            width: 100%;
            .circle-slider {
                position: absolute;
                top: 10%;
                left: 32px;
                width: 24px;
                height: 80%;
            }
        }
    }
}


//- multi search
.multi-search-wrap {
    position: relative;
    .multi-search-head {
        display: flex;
        outline: 0;
        border: 0;
        height: 40px;
        width: 100%;
        border-radius: 25px;
        padding: 0 16px;
        box-shadow: 0 4px 10px 0 rgba(34, 43, 69, 0.1);
        background-color: #fff;
        font-size: 15px;
        &.invalid-input {
            border-color: red !important;
        }
        /* width */
        ::-webkit-scrollbar {
            height: 3px;
        }
        
        
        .multi-search-labels {
            display: flex;
            align-items: center;
            &.share-labels {
                max-width: 335px;
                overflow: hidden;
                overflow-x: auto;

            }
            @media (max-width: 1550px) {
                max-width: 335px;
                overflow: hidden;
                overflow-x: auto;
            }
            margin-right: 12px;
            
            p {
                margin: 0;
                margin-right: 8px;
                padding: 4px 16px;
                border-radius: 20px;
                background-color: #cef5d8;
                color: rgba(22, 32, 53, 0.9);
                font-size: 12px;
                flex-shrink: 0;
                cursor: pointer;
                user-select: none;
                img {
                    max-width: 8px;
                    margin-right: 6px;
                }
                &.selected {
                    color: #fff;
                    background-color: #2ebb55;
                }

                
            }
        }
        .multi-search-input {
            display: flex;
            align-items: center;
            img {
                margin-right: 8px;
            }
            flex: auto;
            input {
                background-color: transparent;
                border: 0;
                outline: 0;
                height: 40px;
                width: 100%;
                font-size: 15px;
                font-family: 'Roboto';
                -webkit-appearance: none;
        
            }
            .add-item-btn {
                margin: 0;
                cursor: pointer;
                transform: rotate(-90deg);
                display: none;
                @media (max-width: 1281px) {
                    display: block;
                }
            }
        }
    }
    .multi-search-body {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 3;
        ul {
            margin: 0;
            padding: 16px;
            margin-top: 8px;
            list-style-type: none;
            background-color: #fff;
            box-shadow: 0 2px 6px 0 #b0bac5;
            border-radius: 4px;
            max-height: 197px;
            overflow-x: hidden;
            overflow-y: auto;
            li {
                padding: 6px 12px;
                font-size: 14px;
                cursor: pointer;
                user-select: none;
                display: flex;
                align-items: center;
                img {
                    margin-right: 8px;
                }
                &:hover {
                    background-color: hsl(211, 10%, 92%);
                }
            }
        }
    }
}


//- columns dropdown
.request-report-wrap {
    .columns-dropdown-contain {
        margin-bottom: 32px;
        .columns-dropdown-wrap {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }   
    }
}

.columns-dropdown-contain {
    .columns-dropdown {
        position: relative;
        .columns-dropdown-head {
            width: 120px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            user-select: none;
            background-color: #fff;
            box-shadow: 0 4px 10px 0 rgba(34, 43, 69, 0.1);
            border-radius: 5px;
            span {
                margin-right: 8px;
                font-size: 14px;
                color: #313541;
            }   
        }
        .columns-dropdown-body {
            position: absolute;
            top: 100%;
            right: 0;
            z-index: 2;
            margin-top: 8px;
            width: 205px;
            height: 309px;
            padding: 16px;
            background-color: #fff;
            box-shadow: 0 4px 10px 0 rgba(34, 43, 69, 0.1);
            display: flex;
            flex-direction: column;
            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;
                li {
                    font-size: 14px;
                    color: #485670;
                    &:not(:last-child) {
                        margin-bottom: 8px;
                    }
                }
            }
            .columns-dropdown-static-list {
                margin-bottom: 16px;
                padding-bottom: 16px;
                border-bottom: 1px solid #f0f2f5;
                ul {
                    li {
                        opacity: 0.5;
                    }
                }
            }
            .columns-dropdown-dynamic-list {
                flex: auto;
                overflow-x: hidden;
                overflow-y: auto;
            }
            ::-webkit-scrollbar {
                width: 4px;
            }
            ::-webkit-scrollbar-thumb {
                background: #f0f2f5;
            }
            ::-webkit-scrollbar-track {
                background: #fff;
            }
        }
    }
}

//- custom checkbox
.custom-checkbox-label {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &.border-label {
        padding: 0 !important;
        margin:  0 !important;
        .custom-checkbox-checkmark {
            top: -10px;
            background-color: transparent;
            border: 1px solid rgba(0, 0, 0, 0.6);
        }
        input:checked ~ .custom-checkbox-checkmark {
            background-color: #2ebb55;
            border: 0;
        }
    }
    &.custom-radio-label {
        &.disable {
            opacity: 0.4;
            cursor: not-allowed;
        }
        .custom-checkbox-checkmark {
            border-radius: 100%;
            &:after {
                width: 12px;
                height: 12px;
                border-radius: 100%;
                border: 0;
                background-color: #2ebb55;
                top: 2px;
                left: 2px;
            }
        }
        input:checked ~ .custom-checkbox-checkmark {
            background-color: #fff;
            border: 1px solid #2ebb55;
        }
    }
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    .custom-checkbox-checkmark {
        position: absolute;
        top: 1.5px;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: #f0f2f5;
        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 7px;
            top: 3px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
    input:checked ~ .custom-checkbox-checkmark {
        background-color: #2ebb55;
    }
    input:checked ~ .custom-checkbox-checkmark:after {
        display: block;
    }

}

//- share form
.share-modal {
    max-width: 768px !important;
    .profile-card {
        max-width: 768px !important;
    }
    .share-form {
        .share-form-content,
        .share-form-report {
            padding: 24px;
            border: 1px solid rgba(22, 32, 53, 0.1);
            border-radius: 5px;
        }
        .share-form-report {
            margin-top: 32px;
            display: flex;
            align-items: center;
            .share-form-report-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 32px;
                height: 32px;
                border-radius: 100%;
                background-color: #cef5d8;
                margin-right: 16px;
            }
            p {
                margin: 0;
                font-size: 14px;
                font-weight: 500;
            }
        }
        .request-form-action {
            .site-btn {
                min-width: 280px;
            }
        }

        textarea {
            height: auto !important;
            padding: 16px;
        }
    }
    .length-msg {
        color: #44c267;
        font-size: 14px;
        font-weight: 500;
    }
}

//- report modal content
.report-content {
    & > strong {
        font-size: 14px;
        display: block;
        margin-bottom: 24px;
        color: #222b45;
    }
    .columns-list-contain {
        & > span {
            display: block;
            font-size: 12px;
            color: rgba(49, 53, 65, 0.7);

        }
        .columns-list-wrap {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .columns-list-item {
                margin-top: 16px;
                margin-right: 32px;
                font-size: 14px;
                color: #485670;
                &.half-opacity {
                    opacity: 0.5;
                }
            }
        }
    }
}

//- general
.mb-30 {
    margin-bottom: 30px !important;
}
.mt-32 {
    margin-top: 32px !important;
}

html[dir=rtl] {
    //- layout
    .request-report-wrap {
        .request-report-head {
            .head-title {
                img {
                    margin-right: 0;
                    margin-left: 8px;
                }
                p {
                    span {
                        &:first-child {
                            display: flex;

                            padding-right: 0;
                            padding-left: 10px;
                        }
                    }
                }
            }
                
                
            .head-actions {
                .actions-list {
                    @media (max-width: 1281px) {
                        right: auto;
                        left: 0;
                    }

                    & > ul {
                        li {
                            &:not(:last-child) {
                                margin-right: 0;
                                margin-left: 16px;
                                @media (max-width: 1281px) {
                                    margin-right: 0;
                                    margin-left: 0;
                                }
                            }
                            &.site-btn.white {
                                img {
                                    transform: rotateZ(0deg);
                                }
                            }
                            
                        }
                    }
                    .reports-dropdown {
                        left: auto;
                        right: 0;
                        @media (max-width: 1281px) {
                            left: 100%;
                            right: auto;
                            margin-right: 0;
                            margin-left: 16px;
                        }
                        ul {
                            li {
                                margin-right: 0;
                                margin-left: 0;
                                
                                img {
                                    margin-right: 0;
                                    margin-left: 8px;
                                }
                            }
                        }
                    }
                }
                .mobile-key {
                    img {
                        margin-right: 0;
                        margin-left: 8px;

                        margin-left: 0;
                        margin-right: 8px;
                        transform: rotateZ(0);
                    }
                }

            }
            
            .head-filter-wrap {
                .head-filter-location {
                    padding-right: 0;
                    padding-left: 16px;
                    @media (max-width: 1124px) {
                        padding-right: 0;
                        padding-left: 0;
                    }
                }
                .head-filter-date {
                    .tabs-requests-container {

                        left: 0;
                        right: auto;
                    }
                }
                .filter-date {
                    margin-right: 0;
                    margin-left: 16px;
                    @media (max-width: 768px) {
                        margin-right: 0;
                        margin-left: 0;
                    }
                }
                .filter-advanced {
                    .filter-btn {
                        margin-right: 0;
                        margin-left: 16px;
                    }
                    
                }

                
            }
            .advanced-filter-contain {
               
                .advanced-filter-wrap {
                    
                    .advanced-filter-select {
                        margin-right: 0;
                        margin-left: 16px;
                        @media (max-width: 768px) {
                            margin-right: 0;
                            margin-left: 0;
                            
                        }
                       
                    }
                    
                }
            }
        }
        .request-report-body {
            
            .request-report-map {
                .circle-slider {
                    left: auto;
                    right: 32px;
                }
            }
        }
    }


    //- multi search
    .multi-search-wrap {
        .multi-search-head {
           
            
            
            .multi-search-labels {
                
                margin-right: 0;
                margin-left: 12px;
                p {
                    margin-right: 0;
                    margin-left: 8px;
                    img {
                        margin-right: 0;
                        margin-left: 6px;
                    }
                    
                }
            }
            .multi-search-input {
                img {
                    margin-right: 0;
                    margin-left: 8px;
                }
            }
        }
        .multi-search-body {
            ul {
                li {
                    img {
                        margin-right: 0;
                        margin-left: 8px;
                    }
                }
            }
        }
        
    }


    

    .columns-dropdown-contain {
        .columns-dropdown {
            .columns-dropdown-head {
               
                span {
                    margin-right: 0;
                    margin-left: 8px;
                }   
            }
            .columns-dropdown-body {
                right: auto;
                left: 0;              
            }
        }
    }

    //- custom checkbox
    .custom-checkbox-label {
        padding-left: 0;
        padding-right: 30px;
        .custom-checkbox-checkmark {
            left: auto;
            right: 0;
           
        }

    }

    //- share form
    .share-modal {
        .share-form {
            .share-form-report {
                .share-form-report-icon {
                    margin-right: 0;
                    margin-left: 16px;
                }
            }
        }
    }
    //- rc slider
    .rc-slider-vertical {
        .rc-slider-track {
            left: auto;
            right: 5px;
        }
        .rc-slider-handle {
            margin-left: 0 !important;
            margin-right: -9px !important;
        }
    }

    //- report modal content
    .report-content {
        
        .columns-list-contain {
            
            .columns-list-wrap {
                .columns-list-item {
                    margin-right: 0;
                    margin-left: 32px;
                }
            }
        }
    }

    .date-text {
        direction: ltr;
    }
}




