.loader {
  border: 6px solid #f3f3f3;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border-top: 6px solid #44c267;
  animation: spin 2s linear infinite;
  margin: auto;
  position: relative;
  top: 30%;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}