.request-container {
    width: 586px;
    height: 100vh;
    background-color: #fff;
    position: fixed;
    top: 0;
    bottom: 0;
    right: -636px;
    z-index: 1002;
    transition: all .3s ease-in-out;
    @media (max-width: 1281px) {
        right: -500px;
        width: 400px;
    }
    &.scroll-container {
        .note {
            position: absolute;
            top: 87px;
            left: 0;
            right: 0;
            z-index: 2;
        }
        @media (max-width: 1281px) {
            .request-form-action {
                margin-bottom: 180px;
            }
        }
        .request-head {
            height: 87px;
        }
        .request-body {
            height: calc(100% - 87px);
            @media (max-width: 1281px) {
                padding-bottom: 82px;
            }
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }
    }
    &.big {
        @media (max-width: 1281px) {
            right: -530px;
            width: 480px;
        }
    
    }
    &.open {
        right: 0;
    }
    &.floatingRight {
        right: auto;
        width: 542px;
        left: -542px;
        &.open {
            left: 0;
        }
        .close-request {
            display: none;
        }
    }
    .request-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;
        background-color: #fff;
        box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.15);
    
    }
    .close-request {
        position: absolute;
        top: 16px;
        left: -50px;
        z-index: 1;
        width: 50px;
        height: 50px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.07);
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .request-head {
        padding: 32px;
        background-color: rgba($color: #222b45, $alpha: 0.05);
        /*&.search-head{
        background-color: #f5f5f6;
        position: sticky;
        opacity: 1;
        top: 0px;
        }*/
        &.single {
            display: flex;
            align-items: center;
        }
        &.request-list-head {
            height: 166px;
            @media (max-width: 1281px) {
                height: 149px;
            }
        }
        &.notification-head {
            background-color: #fff;
            height: 94px;
            p {
                margin-bottom: 0;
                font-size: 20px;
                color: #485670;
                text-transform: capitalize;
            }
            .request-title {
                margin-bottom: 0;
            }
        }
        .request-title {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            &.between {
                justify-content: space-between;
            }
            
            justify-content: space-between;
            margin-bottom: 22px;
            @media (max-width: 1281px) {
                margin-bottom: 8px;
            }
            &.noMargin {
                margin-bottom: 0;
            }
            strong {
                font-size: 20px;
                color: #485670;
                margin-right: 6px;
                text-transform: capitalize;
                @media (max-width: 1281px) {
                    font-size: 15px;
                }
            }
            span {
                font-size: 14px;
                font-weight: 500;
                color: #7a808f;
                text-transform: capitalize;
                @media (max-width: 1281px) {
                    font-size: 12px;
                }
            }
        }
        .request-search-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .request-filter {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: #44c267;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;   
            }
            .request-search {
                width: calc(100% - 72px);
                &.noFilter {
                    width: 100%;
                }
                ::-webkit-input-placeholder { /* Edge */
                    color: #9095a2;
                    font-size: 15px;
                    font-family: 'Roboto';
                }
                  
                :-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: #9095a2;
                    font-size: 15px;
                    font-family: 'Roboto';
                }
                
                ::placeholder {
                    color: #9095a2;
                    font-size: 15px;
                    font-family: 'Roboto';
                }
            }

            
        }
    }
    .request-body {
        padding: 32px;
        &.request-list-body {
            height: calc(100% - 166px);
            @media (max-width: 1281px) {
                height: calc(100% - 149px);
            }
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }
        &.notification-body {
            padding: 0 32px;
            height: calc(100% - 94px);
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }
    }
}



.no-active-request {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &.main {
        width: 100%;
        height: 100%;
        max-width: 309px;
        margin: 0 auto;
    }
    &.sub {
        max-width: 309px;
        margin: 0 auto;
        margin-top: 96px;
    }
    &.table {
        max-width: 350px;
    }
    .no-active-request-icon {
        background: #fb8888;
        width: 80px;
        height: 80px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .no-active-request-body {
        margin-top: 32px;
        text-align: center;
        strong {
            color: #485670;
            font-size: 24px;
            display: block;
            text-align: center;
            margin-bottom: 8px;
            text-transform: capitalize;
        }
        p {
            color: #7b8698;
            font-size: 14px;
        }
        .site-btn {
            margin-top: 16px;
        }
        .clear-search-text {
            margin-top: 16px;
            margin-bottom: 0;
            color: #fb8888;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}


html[dir=rtl] {
    .request-container {
        
        right: auto;
        left: -636px;
        &.open {
            left: 0 !important;
        }
        @media (max-width: 1281px) {
            right: auto;
            left: -500px;
        }
        &.big {
            @media (max-width: 1281px) {
                right: auto;
                left: -530px;
            }
        
        }
        &.floatingRight {
            left: auto;
            right: -542px;
            &.open {
                right: 0;
            }
        }
        .close-request {
            left: auto;
            right: -50px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            img{
                transform: rotate(180deg)
            }

        }
    
        .request-head {
           
            
            .request-title {
                strong {
                    margin-right: 0;
                    margin-left: 6px;
                }
            }
            .request-search-wrap {
                
                .request-search {
                    
                    ::-webkit-input-placeholder { /* Edge */
                        font-family: 'Almari';
                    }
                      
                    :-ms-input-placeholder { /* Internet Explorer 10-11 */
                        font-family: 'Almari';
                    }
                    
                    ::placeholder {
                        font-family: 'Almari';
                    }
                }
    
                
            }
            
        }
        
    }
    
    
    
    
}




