.site-btn {
    height: 50px;
    border-radius: 25px;
    color: #fff;
    background-color: #44c267;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 32px;
    font-size: 14px;
    cursor: pointer;
    border: 0;
    outline: 0 !important;
    font-weight: 500;
    img {
        width: 14px;
        margin-right: 8px;
    }
    .spinner {
        margin-left: 16px;
    }
    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
    &.logout {
        border-radius: 0;
        background-color: transparent;
        height: auto;
        padding: 0;
        color: rgba(22, 32, 53, 0.9);
        span {
            font-size: 16px;
            text-decoration: underline;
        }
    }
}

html[dir=rtl] {
    .site-btn {
        img {
            margin-right: 0;
            margin-left: 8px;
            transform: rotateZ(180deg);
        }
        .spinner {
            margin-left: 0;

            margin-right: 16px;
        }
    }
}